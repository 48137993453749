import React, { useEffect, useState } from "react";
import { Card, Form, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { SelectPicker } from "rsuite";
import { APIS } from "../../App";
import Switch from 'react-switch';
import Fancybox from "../../Component/FancyBox";
import Layout from '../../layout/Layout';
import Cookies from "js-cookie";
import Pagination from "rc-pagination";

var Page_array = [];
var Version_array = [];
var status_array = [];
const Feedback = () => {
    const permission = JSON.parse(localStorage.getItem('permissions'))
    const token = Cookies.get("fmljwt");
    const [Data, setData] = useState([])
    const [size, setSize] = useState(10);
    const [current, setCurrent] = useState(1);
    const [PageHook, setPageHook] = useState([])
    const [loading, setIsLoading] = useState(true)
    const [StatusData, setStatusData] = useState([])
    const [versionData, setversionData] = useState([])
    const [Query, setQuery] = useState({
        status: "",
        version_code: "",
    });

    const GetData = async () => {
        setIsLoading(true);
        try {
            const Form = new FormData()
            Form.append('page', current)
            Form.append('limit', size)
            Form.append("status", Query.status)
            Form.append("version_code", Query.version_code)
            const result = await APIS.post("/getFeedBackData", Form, { headers: { authorization: `${token}` } })
            setData(result?.data)
            PageGetData()
            StatusDropdwon()
            versionDropdwon()
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    const StatusDropdwon = async () => {
        var StatusData = [
            { lable: "On", value: 1 },
            { lable: "Off", value: 0 },
        ]
        status_array = []
        status_array.push({ label: "All", value: "" })
        StatusData.map((val, index) => {
            status_array.push({ label: val.lable, value: val.value })
        })
        setStatusData(status_array)
    };

    const versionDropdwon = async () => {
        const result = await APIS.post("/feedBackVersioncode", {}, { headers: { authorization: `${token}` } })
        
        Version_array = []
        Version_array.push({ label: "All", value: "" })
        result?.data?.data?.map((val, index) => {
            Version_array.push({ label: val.version_code, value: val.version_code })
        })
        setversionData(Version_array)
    };

    // Paggintion Code //
    const paginationData = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize);
    };

    const PageGetData = async () => {
        var PageNumber = [10, 25, 50, 100]
        Page_array = []
        PageNumber.map((val, index) => {
            Page_array.push({ label: val, value: val })
        })
        setPageHook(Page_array)
    };

    const Togglechange = async (id, e, name) => {
        var status;
        if (name === "status") {
            status = e === true ? 1 : 0;
        }
        const Form = new FormData();
        Form.append("id", id);
        Form.append("name", name);
        Form.append("status", status);
        const result = await APIS.post("/updateStatusinFeedback", Form, { headers: { authorization: `${token}` } });
        if (result) {
            toast.success("Status Update successfully");
            GetData();
        }
    };

    const QueryHendler = async (e, name) => {
        setQuery({ ...Query, [name]: e })
        setCurrent(1)
    }

    useEffect(() => {
        GetData()
    }, [current, size, Query])
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">Feedback</h3>
                <div className="page-heading-right">
                    <SelectPicker
                        cleanable={false}
                        data={versionData}
                        searchable={false}
                        defaultValue={""}
                        className="wv-150 my-1 ms-3"
                        onChange={(e) => QueryHendler(e, "version_code")}
                    />
                    <SelectPicker
                        cleanable={false}
                        data={StatusData}
                        searchable={false}
                        defaultValue={""}
                        className="wv-150 my-1 ms-3"
                        onChange={(e) => QueryHendler(e, "status")}
                    />
                    <SelectPicker
                        cleanable={false}
                        data={PageHook}
                        searchable={false}
                        value={size}
                        className="wv-100 my-1 ms-3"
                        onChange={(e) => {
                            setSize(Number(e));
                            setCurrent(1);
                        }}
                    />
                </div>
            </div>
            <div className="page-content">
                <Card className="radiustop-0">
                    <Card.Body>
                        {loading && <div className="loader table-loader"></div>}
                        <Table className="table-bg-primary" striped bordered responsive>
                            <thead>
                                <tr>
                                    <th width="5%" className="text-center">No</th>
                                    <th width="15%">Review</th>
                                    <th width="15%">Ratings</th>
                                    <th width="15%">Contact Information</th>
                                    <th width="12%">Version Code</th>
                                    <th width="14%">Version Name</th>
                                    <th width="14%">Image</th>
                                    <th width="10%" className="text-center">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Data?.data?.length > 0 ? Data?.data?.map((val, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className='text-center'>{(current === 1) ? i + 1 : current * size + i + 1 - size}</td>
                                                <td>{val.review}</td>
                                                <td>{val.ratings}</td>
                                                <td>{val.contact_information}</td>
                                                <td>{val.version_code}</td>
                                                <td>{val.version_name}</td>
                                                <td>{
                                                    val?.images?.length > 0 ? val?.images?.map((img, i) => {
                                                        return (
                                                            <Fancybox>
                                                                <a href={img.image} data-fancybox="gallery" className="me-2">
                                                                    <img src={img.image} className="hv-40 rounded-3" alt="" />
                                                                </a>
                                                            </Fancybox>
                                                        )
                                                    }) : "-"
                                                }</td>
                                                <td className="text-center">
                                                    <Form.Check
                                                        type="switch"
                                                        disabled={permission["Feedback-New"]?.edit == 1 ? false : true}
                                                        onChange={(e) => {
                                                            Togglechange(val.id, e.target.checked, "status");
                                                        }}
                                                        checked={val.status === 1 ? true : false}
                                                    />
                                                </td>
                                            </tr>
                                        )
                                    }) : <tr>
                                        <td colSpan="100%" className="p-0">
                                            <div className='no-found'>
                                                <img src="../../not-found/image.svg" />
                                                <p>No Found Feedback</p>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                        {Data?.totalcount?.totalcount > size ? (
                            <div className="pagination-custom">
                                <Pagination
                                    showTitle={false}
                                    className="pagination-data"
                                    onChange={paginationData}
                                    total={Data.totalcount?.totalcount}
                                    current={current}
                                    pageSize={size}
                                    showSizeChanger={false}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default Feedback