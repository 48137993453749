import Pagination from "rc-pagination";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, Table } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { SelectPicker } from "rsuite";
import { APIS } from "../../App";
import Layout from '../../layout/Layout';
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";
import Cookies from "js-cookie";

const Quotes = () => {
    const token = Cookies.get("fmljwt");
    const permission = JSON.parse(localStorage.getItem('permissions'))
    const [current, setCurrent] = useState(1);
    const [size, setSize] = useState(10);
    const [data, setData] = useState([]);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const getData = async () => {
        setLoading(true);
        try {
            const form = new FormData();
            form.append("page", current);
            form.append("limit", size);
            form.append("search", search);
            const res = await APIS.post("/getAllQuotes", form, { headers: { authorization: `${token}` } });
            setData(res?.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const pageData = [10, 25, 50, 100].map(
        item => ({ label: item, value: item }),
    )

    const paginationData = (page, size) => {
        setCurrent(page);
        setSize(size);
    };

    const handleStatusChange = async (id, status) => {
        setLoading(true);
        try {
            const form = new FormData();
            form.append("id", id);
            form.append("status", status ? 1 : 0);
            const response = await APIS.post("/changeQuotesStatus", form, { headers: { authorization: `${token}` } })
            getData()
            toast.success("Status Update Successfully")
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    let count = 10
    let swalCountdownInterval
    const handleDelete = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: '../../icon/alert.svg',
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr('disabled', true);
                    swalCountdownInterval = setInterval(function () {
                        count--
                        if (count < 1) {
                            $(".counter").text(`OK`)
                            $(".swal2-confirm").attr('disabled', false);
                            clearInterval(swalCountdownInterval)
                        } else {
                            $(".counter").text(`OK (${count})`)
                        }
                    }, 1000);
                }
            })
            .then(async (result) => {
                if (result.isConfirmed) {
                    const deletedata = await APIS.post(`/deleteQuotesDetail/${id}`, {}, { headers: { authorization: `${token}` } })
                    getData();
                    setCurrent(1);
                } else {
                    count = 10
                    clearInterval(swalCountdownInterval)
                }
            });
    };

    useEffect(() => {
        getData()
    }, [current, size, search])

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">Quotes</h3>
                <div className="page-heading-right">
                    <Form.Control
                        type="text"
                        name="reg_no"
                        placeholder="Search Quotes"
                        className="wv-200 my-1 ms-3"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <SelectPicker
                        cleanable={false}
                        data={pageData}
                        searchable={false}
                        value={size}
                        className="wv-100 my-1 ms-3"
                        onChange={(e) => {
                            setCurrent(1)
                            setSize(e)
                        }}
                    />
                    {permission["Quotes"]?.add == 1 && <Link to="/quotes/add" className="my-1 ms-3">
                        <Button variant="primary" value="create">Add New</Button>
                    </Link>}
                </div>
            </div>
            <div className="page-content">
                <Card>
                    <Card.Body>
                        {loading && <div className="loader table-loader" ></div>}
                        <Table bordered responsive>
                            <thead>
                                <tr>
                                    <th width="5%" className="text-center">No</th>
                                    <th width="35%">Quotes</th>
                                    <th width="40%">Other Quotes</th>
                                    <th width="10%" className="text-center">Status</th>
                                    <th width="10%" className="text-center">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data?.data?.length > 0 ? data?.data?.map((val, index) => {
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td className='text-center'>{(current === 1) ? index + 1 : current * size + index + 1 - size}</td>
                                                    <td>{val.en}</td>
                                                    <td>{val.hi}</td>
                                                    <td className="text-center">
                                                        <Form.Check type="switch" disabled={permission["Quotes"]?.edit == 1 ? false : true}
                                                            checked={val?.status} onChange={(e) => handleStatusChange(val.id, e.target.checked)} />
                                                    </td>
                                                    <td className="text-center">
                                                        <Link to={`/quotes/view/${val.id}`}>
                                                            <Button variant="outline-warning" size="sm" className="me-2 btn-icon"><i className='bx bx-show'></i></Button>
                                                        </Link>
                                                        {permission["Quotes"]?.delete == 1 && <Button variant="outline-danger" size="sm" className="btn-icon" onClick={() => handleDelete(val.id)} ><i className='bx bx-trash-alt' ></i></Button>
                                                        }                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    }) : <tr>
                                        <td colSpan="100%" className="p-0">
                                            <div className='no-found'>
                                                <img src="../../not-found/image.svg" />
                                                <p>No Found Quotes</p>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                        {
                            data.totalcount > size ?
                                <div className="pagination-custom">
                                    <Pagination
                                        showTitle={false}
                                        className="pagination-data"
                                        onChange={paginationData}
                                        total={data?.totalcount}
                                        current={current}
                                        pageSize={size}
                                        showSizeChanger={false}
                                    />
                                </div> : ""
                        }
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default Quotes