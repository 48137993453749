import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Col, Form, Row, Table } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { SelectPicker } from "rsuite";
import { APIS, AuthContext } from "../../App";
import Swal from "sweetalert2";
import Layout from '../../layout/Layout';
import $ from "jquery";
import Cookies from "js-cookie";
import Pagination from "rc-pagination";

var Page_array = [];
const AppUpdate = () => {
  const permission = JSON.parse(localStorage.getItem('permissions'))
  const token = Cookies.get("fmljwt");
  const [Data, setData] = useState([])
  const [perPage, setPerPage] = useState(10);
  const [size, setSize] = useState(perPage);
  const [current, setCurrent] = useState(1);
  const [PageHook, setPageHook] = useState([])
  const [loading, setIsLoading] = useState(true)
  const GetData = async () => {
    setIsLoading(true);
    try {
      const result = await APIS.post("/getAppDataDetail", {}, { headers: { authorization: `${token}` } })
      setData(result?.data?.data)
      PageGetData()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false);
    }
  }

  // Paggintion Code //
  const getData1 = (current, pageSize) => {
    return Data?.slice((current - 1) * pageSize, current * pageSize);
  };

  const PerPageChange = (value) => {
    setSize(value);
    const newPerPage = Math.ceil(Data?.length / value);
    if (current > newPerPage) {
      setCurrent(newPerPage);
    }
  };

  const paginationData = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize);
  };

  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "prev") {
      return <button className="paggination-btn">Previous</button>;
    }
    if (type === "next") {
      return <button className="paggination-btn">Next</button>;
    }
    return originalElement;
  };

  const PageGetData = async () => {
    var PageNumber = [10, 25, 50, 100]
    Page_array = []
    PageNumber.map((val, index) => {
      Page_array.push({ label: val, value: val })
    })
    setPageHook(Page_array)
  };

  const Togglechange = async (id, e, name) => {
    let start_io_ads_enable;
    let affilation_program_enable;

    if (name === "start_io_ads_enable") {
      start_io_ads_enable = e === true ? 1 : 0;
    }
    if (name === "affilation_program_enable") {
      affilation_program_enable = e === true ? 1 : 0;
    }
    const Form = new FormData();
    Form.append("id", id);
    start_io_ads_enable != undefined && Form.append("start_io_ads_enable", start_io_ads_enable);
    affilation_program_enable != undefined && Form.append("affilation_program_enable", affilation_program_enable);
    let result
    result = await APIS.post("/changeStatusForAppdata", Form, { headers: { authorization: `${token}` } });

    if (result) {
      toast.success("Status Update successfully");
      GetData();
    }
  };

  let count = 10
  let swalCountdownInterval
  const DeleteData = async (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger btn-lg counter",
        cancelButton: "btn btn-primary btn-lg me-3",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        imageUrl: '../../icon/alert.svg',
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: "OK (10)",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        reverseButtons: true,
        didOpen: () => {
          $(".swal2-confirm").attr('disabled', true);
          swalCountdownInterval = setInterval(function () {
            count--
            if (count < 1) {
              $(".counter").text(`OK`)
              $(".swal2-confirm").attr('disabled', false);
              clearInterval(swalCountdownInterval)
            } else {
              $(".counter").text(`OK (${count})`)
            }
          }, 1000);
        }
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await APIS.post(`/deleteAppdata/${id}`, {}, { headers: { authorization: `${token}` } });
          GetData();
        } else {
          count = 10
          clearInterval(swalCountdownInterval)
        }
      });
  };

  useEffect(() => {
    GetData()
  }, [])
  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1">App Update</h3>
        <div className="page-heading-right">
          <SelectPicker
            cleanable={false}
            data={PageHook}
            searchable={false}
            value={size}
            className="wv-100 my-1 ms-3"
            onChange={(e) => {
              setSize(Number(e));
              setCurrent(1);
            }}
          />
          {permission["App-Update"]?.add == 1 && <Link to="/app-update/add" className="my-1 ms-3">
            <Button variant="primary" value="create">Add New</Button>
          </Link>}
        </div>
      </div>
      <div className="page-content">
        <Card className="radiustop-0">
          <Card.Body>
            {loading && <div className="loader table-loader"></div>}
            <Table className="table-bg-primary" striped bordered responsive>
              <thead>
                <tr>
                  <th width="5%" className="text-center">No</th>
                  <th width="14%">Title</th>
                  <th width="13%">Minimum Version Code</th>
                  <th width="13%">Current Live Version</th>
                  <th width="17%">Package Name</th>
                  <th width="14%" className='text-center'>Start iO ADS</th>
                  <th width="14%" className='text-center'>Affiliation Program</th>
                  <th width="10%" className='text-center'>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  getData1(current, size)?.map((val, i) => {
                    return (
                      <tr key={i}>
                        <td className='text-center'>{(current === 1) ? i + 1 : current * size + i + 1 - size}</td>
                        <td>{val.title}</td>
                        <td>{val.version_code}</td>
                        <td>{val.current_version}</td>
                        <td>{val.package_name}</td>

                        <td className='text-center'>
                          <Form.Check
                            type="switch"
                            name="start_io_ads_enable"
                            disabled={permission["App-Update"]?.edit == 1 ? false : true}
                            onChange={(e) => {
                              Togglechange(val.id, e.target.checked, "start_io_ads_enable");
                            }}
                            checked={val.start_io_ads_enable === 1 ? true : false}
                          />
                        </td>
                        <td className='text-center'>
                          <Form.Check
                            type="switch"
                            disabled={permission["App-Update"]?.edit == 1 ? false : true}
                            name="affilation_program_enable"
                            onChange={(e) => {
                              Togglechange(val.id, e.target.checked, "affilation_program_enable");
                            }}
                            checked={val.affilation_program_enable === 1 ? true : false}
                          />

                        </td>
                        <td>
                          <div className='d-flex align-items-center justify-content-center'>
                            <Link to={`/app-update/view/${val.id}`} className="btn btn-icon btn-transparent btn-primary">
                              <i className='bx bx-show'></i>
                            </Link>
                            <span className='border-start py-2 mx-2'></span>
                            {permission["App-Update"]?.delete == 1 && 
                              <Button variant="danger" onClick={() => DeleteData(val.id)} size="sm" className="btn-icon btn-transparent">
                                <i className='bx bx-trash' ></i>
                              </Button>
                            }
                          </div>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
              {
                loading == false && Data?.length === 0 ? <tr>
                  <td colSpan="100%" className="p-0">
                    <div className='no-found'>
                      <img src="../../not-found/image.svg" />
                      <p>No Found App Update</p>
                    </div>
                  </td>
                </tr> : ""
              }
            </Table>
            {Data?.length > size ? (
              <div className="pagination-custom">
                <Pagination
                  showTitle={false}
                  className="pagination-data"
                  onChange={paginationData}
                  total={Data.length}
                  current={current}
                  pageSize={size}
                  showSizeChanger={false}
                  itemRender={PrevNextArrow}
                  onShowSizeChange={PerPageChange}
                />
              </div>
            ) : (
              ""
            )}
          </Card.Body>
        </Card>
      </div>
    </Layout>
  )
}

export default AppUpdate