import React, { useContext, useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import { APIS } from "../../App";
import Layout from '../../layout/Layout';
import Cookies from "js-cookie";
import ApexCharts from 'react-apexcharts';
import moment from 'moment';
import { AuthContext } from "../../App";
import DateRangePicker from "react-bootstrap-daterangepicker";

var state_array = [];
const RcCount = () => {
  const token = Cookies.get("fmljwt");
  const [Data, setData] = useState([])
  const [stateHook, setstateHook] = useState([])
  const [loading, setIsLoading] = useState(true)
  const [chatValue, setChatValue] = useState({ state: [], count: [], totlecount: "" })
  const { theme, setTheme } = useContext(AuthContext)
  const bluecolor = "#008FFB"
  const greencolor = "#00D8B6"

  useEffect(() => {
    let states = []
    let counts = []
    let totlecount = 0
    Data?.data?.length > 0 && Data?.data?.forEach(element => {
      states.push(element?.state)
      counts.push(Number(element?.count))
      totlecount += Number(element?.count)
    });
    setChatValue({ ...chatValue, state: states, count: counts, totlecount })
  }, [Data])
  const [chartRcCount, setchartRcCount] = useState({
    series: [
      {
        name: 'Count',
        data: chatValue?.count
      },
      // {
      //     name: 'FULL',
      //     data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 85, 75, 54, 75, 54]
      // }
    ],
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      },
      height: 350
    },
    colors: [greencolor, bluecolor],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '15%',
        endingShape: 'rounded',
        borderRadius: 4,
        dataLabels: {
          total: {
            enabled: true,
            offsetX: 0,
            style: {
              color: `${theme == "dark" ? '#b2b2b2' : '#6C737D'}`,
              fontSize: '12px',
              fontFamily: 'Maven Pro',
              fontWeight: 500,
            }
          }
        }
      }
    },
    legend: {
      show: false,
    },
    states: {
      hover: {
        filter: {
          type: 'none',
          value: 0,
        }
      },
      active: {
        filter: {
          type: 'none',
          value: 0,
        }
      },
    },
    grid: {
      borderColor: `${theme == "dark" ? '#2e2d32' : '#DEF4EB'}`,
    },
    dataLabels: {
      enabled: false,
      formatter: (val) => {
        if (val < 1000) {
          return val;
        } else if (val < 100000) {
          return (val / 1000).toFixed(1) + ' K';
        } else if (val < 10000000) {
          return (val / 100000).toFixed(1) + ' L';
        } else {
          return (val / 10000000).toFixed(1) + ' cr';
        }
      }
    },
    stroke: {
      show: true,
    },
    xaxis: {
      categories: [],
      labels: {
        style: {
          colors: "#6C737D",
          fontSize: '14px',
          fontFamily: 'Maven Pro',
          fontWeight: 500,
        },
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: "#6C737D",
          fontSize: '14px',
          fontFamily: 'Maven Pro',
          fontWeight: 500,
        },
        formatter: (val) => {
          if (val < 1000) {
            return val;
          } else if (val < 100000) {
            return (val / 1000).toFixed(1) + ' K';
          } else if (val < 10000000) {
            return (val / 100000).toFixed(1) + ' L';
          } else {
            return (val / 10000000).toFixed(1) + ' cr';
          }
        }
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      enabled: true,
      shared: true,
      intersect: false,
      y: {
        formatter: (val) => {
          return val;  
        }
      }
      // custom: function ({ series, seriesIndex, dataPointIndex, w }) {
      //   const partialData = series && series[0][dataPointIndex];
      //   const fullData = series && series[1][dataPointIndex];
      //   const total = partialData + fullData;
      //   const seriesName = w.globals.labels[dataPointIndex];
      //   const cyellow = w.config.colors[0];
      //   const cpurple = w.config.colors[1];
      //   return `
      //             <div class="tooltipbox">
      //                 <h6>${seriesName + " - " + total}</h6>
      //                 <div class="tooltipbody">
      //                     <p><span class="legend" style="background-color:${cyellow}"></span> PARTIAL : <span class="tooltiptext">&nbsp;${partialData}</span></p>
      //                     <p><span class="legend" style="background-color:${cpurple}"></span> FULL : <span class="tooltiptext">&nbsp;${fullData}</span></p>
      //                 </div>
      //             </div>
      //         `;
      // }
    },
  });

  const [state, setState] = useState({
    start: moment().subtract(29, 'days'),
    end: moment(),
  });
  const { start, end } = state;
  const handleCallback = (start, end) => {
    setState({ start, end });
  };

  const GetData = async (state) => {
    setIsLoading(true);
    try {
      const result = await APIS.post("/rcCount", { state: state }, { headers: { authorization: `${token}` } })
      setData(result.data)
      StateGetData()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false);
    }
  }

  const StateGetData = async () => {
    const collectionNames = ['AN', 'AP', 'AR', 'AS', 'BR', 'CH', 'CG', 'DD', 'DL', 'GA', 'GJ', 'HR', 'HP', 'JK', 'JH', 'KA', 'KR', 'KL', 'LA', 'LD', 'MP', 'MH', 'MN', 'ML', 'MZ', 'NL', 'OD', 'PY', 'PB', 'RJ', 'SK', 'TN', 'TS', 'TR', 'UP', 'UK', 'WB'];
    state_array = []
    state_array.push({ label: "All", value: "" })
    collectionNames.map((val, index) => {
      state_array.push({ label: val, value: val })
    })
    setstateHook(state_array)
  };

  useEffect(() => {
    GetData()
  }, [])
  useEffect(() => {
    setchartRcCount(
      {
        ...chartRcCount, series: [
          {
            name: 'Count',
            data: chatValue?.count
          }],
        xaxis: {
          categories: chatValue?.state,
          labels: {
            style: {
              colors: "#6C737D",
              fontSize: '14px',
              fontFamily: 'Maven Pro',
              fontWeight: 500,
            },
          }
        }
      })
  }, [chatValue])


  useEffect(() => {
    setchartRcCount((prevState) => ({
      ...prevState,
      plotOptions: {
        bar: {
          dataLabels: {
            total: {
              style: {
                color: `${theme == "dark" ? '#b2b2b2' : '#6C737D'}`,
              }
            }
          }
        }
      },
      grid: {
        borderColor: `${theme == "dark" ? '#2e2d32' : '#DEF4EB'}`,
      },
    }));
  }, [theme]);

  const dateOptions = ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly'].map(
    item => ({ label: item, value: item })
  );


  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1">RC Count <span>{chatValue?.totlecount > 0 && chatValue?.totlecount}</span></h3>
        <div className="page-heading-right">
          <SelectPicker
            cleanable={false}
            data={stateHook}
            searchable={false}
            defaultValue={""}
            className="wv-100 my-1 ms-3"
            onChange={(e) => GetData(e)}
          />
          <SelectPicker
            cleanable={false}
            data={dateOptions}
            searchable={false}
            defaultValue={"Daily"}
            className="wv-100 my-1 ms-3"
            placeholder="Select Date"
          />

          <DateRangePicker initialSettings={{
            startDate: start.toDate(),
            endDate: end.toDate(),
            ranges: {
              Today: [moment().toDate(), moment().toDate()],
              Yesterday: [
                moment().subtract(1, 'days').toDate(),
                moment().subtract(1, 'days').toDate(),
              ],
              'Last 7 Days': [
                moment().subtract(6, 'days').toDate(),
                moment().toDate(),
              ],
              'Last 30 Days': [
                moment().subtract(29, 'days').toDate(),
                moment().toDate(),
              ],
              'This Month': [
                moment().startOf('month').toDate(),
                moment().endOf('month').toDate(),
              ],
              'Last Month': [
                moment().subtract(1, 'month').startOf('month').toDate(),
                moment().subtract(1, 'month').endOf('month').toDate(),
              ],
            },
          }}>
            <input type="text" name="date" placeholder="Select Date" className="form-control wv-225 my-1 ms-3 " />
          </DateRangePicker>
        </div>
      </div>
      <div className="page-content">
        <Card className="radiustop-0">
          <Card.Body className="border-bottom py-0 px-1">
            <ApexCharts options={chartRcCount} series={chartRcCount.series} type="bar" height={325} />
          </Card.Body>
          <Card.Body>
            {loading && <div className="loader table-loader"></div>}
            <Table className="table-bg-primary" bordered responsive striped>
              <thead>
                <tr>
                  <th width="5%" className="text-center">No</th>
                  <th width="20%" className="text-center">State</th>
                  <th width="25%" className="text-center">Full RC Details</th>
                  <th width="25%" className="text-center">Partial RC Details</th>
                  <th width="25%" className="text-center">Total</th>
                </tr>
              </thead>
              <tbody>
                {
                  Data?.data?.length > 0 ? Data?.data?.map((val, i) => {
                    return (
                      <tr key={i}>
                        <td className='text-center'>{i + 1}</td>
                        <td className="text-center">{val.state}</td>
                        <td className="text-center">-</td>
                        <td className="text-center">-</td>
                        <td className="text-center">{val.count}</td>
                      </tr>
                    )
                  }) : <tr>
                    <td colSpan="100%" className="p-0">
                      <div className='no-found'>
                        <img src="../../not-found/image.svg" />
                        <p>No Found RC Count</p>
                      </div>
                    </td>
                  </tr>
                }
                {Data.totalcount && <tr>
                  <td colSpan="4" className="text-end text-success fw-700">Total Record Of All State : </td>
                  <td className="text-center">{Data.totalcount}</td>
                </tr>}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  )
}

export default RcCount