import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { API, APIS } from "../../App";
import Switch from 'react-switch';
import Layout from '../../layout/Layout';
import Cookies from "js-cookie";

const ApipurchasePriorityAdd = () => {
  const token = Cookies.get("fmljwt");
  const params = useParams()
  const [validated, setvalidated] = useState(false)
  const [status, setstatus] = useState(0)
  const navigate = useNavigate()
  const [Data, setData] = useState({
    third_party_api: "",
    status: 0,
  })

  const Getview = async (Eid) => {
    const result = await APIS.post(`/getSingleapiPurchasePrioritydata/${params.id}`, {}, { headers: { authorization: `${token}` } });
    setData(result.data.data[0]);
  };

  useEffect(() => {
    params.id && Getview()
  }, [params.id])


  const SaveData = async (e) => {
    setData({ ...Data, [e.target.name]: e.target.value })
  }

  const Submite = async () => {
    if ((Data.third_party_api == undefined || Data.third_party_api == "")) {
      setvalidated(true)
    } else {
      const Form = new FormData()
      Form.append('third_party_api', Data.third_party_api)
      Form.append('status', Data?.status)
      if (params.id) {
        const Result = await APIS.post(`/updateApiPurchasePrioritydata/${params.id}`, Form, { headers: { authorization: `${token}` } });
        if (Result) {
          toast.success("Data Update successfully");
          navigate(`/api-purchase-priority/view/${params.id}`)
        }
      } else {

        const Result = await APIS.post(`/storeApiPurchasePriority`, Form, { headers: { authorization: `${token}` } })
        if (Result) {
          toast.success("Data Saved successfully");
          navigate(`/api-purchase-priority`)
        }
      }
    }
  }
  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>API Purchase Priority {params?.id ? "Edit" : "Add"}</h3>
        <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
          <Breadcrumb.Item >
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item >
            <Link to="/api-purchase-priority">API Purchase Priority</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>API Purchase Priority {params?.id ? "Edit" : "Add"}</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="page-content">
        <Form noValidate validated={validated}>
          <Card className="mb-4 radiustop-0">
            <Card.Body>
              <Row>
                <Col md={6}>
                  <Form.Label htmlFor="icon">Thirdparty API</Form.Label>
                  <Form.Control
                    type="text"
                    name="third_party_api"
                    onChange={SaveData}
                    value={Data?.third_party_api}
                    className="my-2"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Thirdparty API Field Is Require
                  </Form.Control.Feedback>
                </Col>

                <Col md={2}>
                  <Form.Label htmlFor="status" className="d-block mb-2">
                    Status
                  </Form.Label>
                  <Form.Check
                    type="switch"
                    name="status"
                    onChange={(e) => {
                      e.target.checked === true ? setData({ ...Data, status: 1 }) : setData({ ...Data, status: 0 })
                    }}
                    checked={Data?.status === 1 ? true : false}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="text-end">
              <Button variant="primary" onClick={Submite} className="me-3">Save</Button>
              <Link to={params?.id ? `/api-purchase-priority/view/${params.id}` : "/api-purchase-priority"}>
                <Button variant="secondary">Cancel</Button>
              </Link>
            </Card.Footer>
          </Card>
        </Form>
      </div>
    </Layout>
  )
}

export default ApipurchasePriorityAdd