import React, { useEffect, useState } from "react";
import { Button, Card, Form, InputGroup, Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { SelectPicker } from "rsuite";
import { APIS } from "../../App";
import Layout from '../../layout/Layout';
import Cookies from "js-cookie";
import Pagination from "rc-pagination";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

var Page_array = [];
var state_array = [];
const RcBlock = () => {
  const permission = JSON.parse(localStorage.getItem('permissions'))

  const token = Cookies.get("fmljwt");
  const [Data, setData] = useState([])
  const [size, setSize] = useState(10);
  const [current, setCurrent] = useState(1);
  const [PageHook, setPageHook] = useState([])
  const [stateHook, setstateHook] = useState([])
  const [loading, setIsLoading] = useState(true)
  const [SaveData, setSaveData] = useState({
    reg_no: "",
  })
  const [query, setquery] = useState({
    search: ""
  })
  const GetData = async () => {
    setIsLoading(true);
    try {
      const Form = new FormData()
      Form.append('page', current)
      Form.append('limit', size)
      Form.append('state', query?.search)
      const result = await APIS.post("/rcBlockSearch", Form, { headers: { authorization: `${token}` } })
      setData(result.data)
      PageGetData()
      StateGetData()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false);
    }
  }

  const SaveDatahendler = (e) => {
    if (e.target.name === "reg_no") {
      const input = e.target.value;
      const regex = /^[A-Za-z]{2}\d{2}[A-Za-z]{1,2}\d{4}$/;
      if (regex.test(input) || input === "") {
        setSaveData({ ...SaveData, [e.target.name]: e.target.value });
      }
    } else {
      setSaveData({ ...SaveData, [e.target.name]: e.target.value });
    }
  };


  const Sumite = async () => {
    if (SaveData.reg_no == "") {
      toast.error("Required Registation No ")
    } else {
      const Form = new FormData()
      Form.append('rc_no', SaveData.reg_no)
      const result = await APIS.post('/storeRcblockregNum', Form, { headers: { authorization: `${token}` } })
      if (result.data.Response_massage == "all Ready Added") {
        toast.error("Invelid Registration No!")
      } else {
        toast.success("Data Save Successfuly ...")
        GetData()
        setSaveData({ reg_no: "" })
        document.getElementById('reg_no_input').value = '';
      }
    }
  }
  const paginationData = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize);
  };


  const PageGetData = async () => {
    var PageNumber = [10, 25, 50, 100]
    Page_array = []
    PageNumber.map((val, index) => {
      Page_array.push({ label: val, value: val })
    })
    setPageHook(Page_array)
  };

  const StateGetData = async () => {
    const collectionNames = ['AN', 'AP', 'AR', 'AS', 'BR', 'BH', 'CH', 'CG', 'DD', 'DL', 'GA', 'GJ', 'HR', 'HP', 'JK', 'JH', 'KA', 'KR', 'KL', 'LA', 'LD', 'MP', 'MH', 'MN', 'ML', 'MZ', 'NL', 'OD', 'PY', 'PB', 'RJ', 'SK', 'TN', 'TS', 'TR', 'UP', 'UK', 'WB'];
    state_array = []
    state_array.push({ label: "All", value: "" })
    collectionNames.map((val, index) => {
      state_array.push({ label: val, value: val })
    })
    setstateHook(state_array)
  };

  const Togglechange = async (id, e, name) => {
    var status;

    if (name === "status") {
      status = e.target.checked === true ? 1 : 0;
    }
    const Form = new FormData();
    Form.append("id", id);
    Form.append("status", status);
    const result = await APIS.post("/changeRCblockStatus", Form, { headers: { authorization: `${token}` } });
    if (result) {
      toast.success("Status Update successfully");
      GetData();
    }
  };


  const searching = async (e, name) => {
    if (name == "search") {
      setquery({ ...query, [name]: e })
    }
    setCurrent(1);
      }


  useEffect(() => {
    GetData()
  }, [current, size, query])

  const dateOptions = ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly'].map(
    item => ({ label: item, value: item })
  );

  const [dateRanges, setdateRanges] = useState({
    start: moment().subtract(29, 'days'),
    end: moment(),
  });
  const { start, end } = dateRanges;

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1">RC Block <span>123</span></h3>
        <div className="page-heading-right">
          <div className="inputgroup my-1">
              <Form.Control
                  type="text"
                  name="searchvalue"
                  placeholder="Search by registration no."
                  className="wv-275 ms-3"
              />
              <i className="bx bx-search"/>
          </div>
          
          <SelectPicker
            cleanable={false}
            data={stateHook}
            searchable={false}
            defaultValue={""}
            className="wv-150 my-1 ms-3"
            placeholder="Select State"
            onChange={(e) => searching(e, "search")}
          />
          <SelectPicker
            cleanable={false}
            data={dateOptions}
            searchable={false}
            defaultValue={""}
            placeholder="Select Date"
            className="wv-150 my-1 ms-3"
          />
          <DateRangePicker initialSettings={{ 
                startDate: start.toDate(),
                endDate: end.toDate(),
                ranges: {
                    Today: [moment().toDate(), moment().toDate()],
                    Yesterday: [
                        moment().subtract(1, 'days').toDate(),
                        moment().subtract(1, 'days').toDate(),
                    ],
                    'Last 7 Days': [
                        moment().subtract(6, 'days').toDate(),
                        moment().toDate(),
                    ],
                    'Last 30 Days': [
                        moment().subtract(29, 'days').toDate(),
                        moment().toDate(),
                    ],
                    'This Month': [
                        moment().startOf('month').toDate(),
                        moment().endOf('month').toDate(),
                    ],
                    'Last Month': [
                        moment().subtract(1, 'month').startOf('month').toDate(),
                        moment().subtract(1, 'month').endOf('month').toDate(),
                    ],
                },
            }}>
            <input type="text" name="date" placeholder="Select Date" className="form-control wv-225 my-1 ms-3 " />
          </DateRangePicker>
          <SelectPicker
            cleanable={false}
            data={PageHook}
            searchable={false}
            value={size}
            className="wv-100 my-1 ms-3"
            onChange={(e) => {
              setSize(Number(e));
              setCurrent(1);
            }}
          />
          <div>
            <InputGroup className="my-1 ms-3">
              <Form.Control
                type="text"
                name="reg_no"
                maxLength={10}
                id="reg_no_input"
                placeholder="Enter Registation No."
                className="wv-200"
                onChange={SaveDatahendler}
              />
              <Button variant="primary" value="create" onClick={Sumite}>Block</Button>
            </InputGroup>
          </div>
        </div>
      </div>
      <div className="page-content">
        <Card className="radiustop-0">
          <Card.Body>
            {loading && <div className="loader table-loader"></div>}
            <Table className="table-bg-primary" bordered striped responsive>
              <thead>
                <tr>
                  <th width="5%" className="text-center">No</th>
                  <th width="35%" className='text-center'>Registation No</th>
                  <th width="40%" className='text-center'>Owner Name</th>
                  <th width="10%" className="text-center">Status</th>
                  <th width="10%" className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  Data?.data?.length > 0 ? Data?.data?.map((val, i) => {
                    return (
                      <tr key={i}>
                        <td className='text-center'>{(current === 1) ? i + 1 : current * size + i + 1 - size}</td>
                        <td className='text-center'>{val.reg_no}</td>
                        <td className='text-center'>-</td>
                        <td className="text-center">
                          <Form.Check
                            type="switch"
                            name="reward_status"
                            onChange={(e) => {
                              Togglechange(val.id, e, "status");
                            }}
                            disabled={permission["Rc-Block"]?.edit == 1 ? false : true}
                            checked={val.status === 1 ? true : false}
                          />
                        </td>
                        <td className='text-center'>
                          <div className='d-flex align-items-center justify-content-center'>
                              <Button variant="danger" className="btn-icon btn-transparent">
                               <i className='bx bx-trash'></i>
                              </Button>
                          </div>
                        </td>
                      </tr>
                    )
                  }) : <tr>
                    <td colSpan="100%" className="p-0">
                      <div className='no-found'>
                        <img src="../../not-found/image.svg" />
                        <p>No Found RC Block</p>
                      </div>
                    </td>
                  </tr>
                }
              </tbody>
            </Table>
            {Data?.totalcount?.searchcount > size ? (
              <div className="pagination-custom">
                <Pagination
                  showTitle={false}
                  className="pagination-data"
                  onChange={paginationData}
                  total={Data?.totalcount?.searchcount}
                  current={current}
                  pageSize={size}
                  showSizeChanger={false}
                />
              </div>
            ) : (
              ""
            )}
          </Card.Body>
        </Card>
      </div>
    </Layout>
  )
}

export default RcBlock