import React, { useContext, useEffect, useState } from "react";
import { Card } from 'react-bootstrap';
import ApexCharts from 'react-apexcharts'
import { SelectPicker } from 'rsuite';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import { AuthContext } from "../../App";

const data = ['Eugenia', 'Bryan', 'Linda', 'Nancy', 'Lloyd', 'Alice', 'Julia', 'Albert'].map(
    item => ({ label: item, value: item })
);

const Request = () => {
    const {theme,setTheme} = useContext(AuthContext)
    const bluecolor = "#008FFB"
    const greencolor = "#00D8B6"

    const [chartRequest, setChartRequest] = useState({
        series: [
            {
                name: 'SUCCESS',
                data: [44, 55, 57, 56, 61, 58, 63, 60, 70, 30, 45, 50]
            },
            {
                name: 'FAIL',
                data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 85, 75, 54]
            }
        ],
        chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            },
            height: 350
        },
        colors: [greencolor, bluecolor],
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '15%',
                endingShape: 'rounded',
                borderRadius: 4,
                dataLabels: {
                    total: {
                        enabled: true,
                        offsetX: 0,
                        style: {
                            color: `${theme=="dark"?'#b2b2b2':'#6C737D'}`,
                            fontSize: '12px',
                            fontFamily: 'Maven Pro',
                            fontWeight: 500,
                        }
                    }
                }
            }
        },
        legend: {
            show: false,
        },
        states: {
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                }
            },
            active: {
                filter: {
                    type: 'none',
                    value: 0,
                }
            },
        },
        grid: {
            borderColor:`${theme=="dark"?'#2e2d32':'#DEF4EB'}` ,
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
        },
        xaxis: {
            categories: ['1/1', '2/1', '3/1', '4/1', '5/1', '6/1', '7/1', '8/1', '9/1', '10/1','11/1','12/1'],
            labels: {
                style: {
                    colors: "#6C737D",
                    fontSize: '14px',
                    fontFamily: 'Maven Pro',
                    fontWeight: 500,
                },
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: "#6C737D",
                    fontSize: '14px',
                    fontFamily: 'Maven Pro',
                    fontWeight: 500,
                },
                formatter: (val) => {
                    return val / 10 + 'L'
                }
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            enabled: true,
            shared: true,
            intersect: false,
            custom: function({series, seriesIndex, dataPointIndex, w}) {
                const successData = series&&series[0][dataPointIndex];
                const failData = series&&series[1][dataPointIndex];
                const total = successData + failData;
                const seriesName = w.globals.labels[dataPointIndex];
                const cblue = w.config.colors[0];
                const cgreen = w.config.colors[1];
                return `
                    <div class="tooltipbox">
                        <h6>Top Request In Day ${seriesName + " - " + total}</h6>
                        <div class="tooltipbody">
                            <p><span class="legend" style="background-color:${cblue}"></span> SUCCESS : <span class="tooltiptext">&nbsp;${successData}</span></p>
                            <p><span class="legend" style="background-color:${cgreen}"></span> FAIL : <span class="tooltiptext">&nbsp;${failData}</span></p>
                        </div>
                    </div>
                `;
            }
        },
    });

    useEffect(() => {
        setChartRequest((prevState) => ({
            ...prevState,
            plotOptions: {
                bar: {
                    dataLabels: {
                        total: {
                            style: {
                                color: `${theme=="dark"?'#b2b2b2':'#6C737D'}`,
                            }
                        }
                    }
                }
            },
            grid: {
                borderColor: `${theme=="dark"?'#2e2d32':'#DEF4EB'}` ,
            },
        }));
    }, [theme]);

    const [state, setState] = useState({
        start: moment().subtract(29, 'days'),
        end: moment(),
    });
    const { start, end } = state;
    const handleCallback = (start, end) => {
        setState({ start, end });
    };

    return(
        <>
            <Card>
                <Card.Header className='pb-0'>
                    <div className="chart-title">
                        <h4>Request</h4>
                        <div className='chartfilter'>
                            <div className="chartlegend">
                                <p><span style={{ backgroundColor: greencolor }}></span> SUCCESS</p>
                                <p><span style={{ backgroundColor: bluecolor }}></span> FAIL</p>
                            </div>
                            <SelectPicker
                                cleanable={false}
                                data={data}
                                size="md"
                                searchable={false}
                                defaultValue={""}
                                placeholder="WEB"
                                placement="bottomEnd"
                                className="wv-100 ms-3"
                            />
                            <SelectPicker
                                cleanable={false}
                                data={data}
                                size="md"
                                searchable={false}
                                defaultValue={""}
                                placeholder="CHALLAN"
                                placement="bottomEnd"
                                className="wv-125 ms-3"
                            />
                            <SelectPicker
                                cleanable={false}
                                data={data}
                                size="md"
                                searchable={false}
                                defaultValue={""}
                                placeholder="SELECT STATE"
                                placement="bottomEnd"
                                className="wv-150 ms-3"
                            />
                            <SelectPicker
                                cleanable={false}
                                data={data}
                                size="md"
                                searchable={false}
                                defaultValue={""}
                                placeholder="DAILY"
                                placement="bottomEnd"
                                className="wv-125 ms-3"
                            />
                            <DateRangePicker
                                initialSettings={{
                                    startDate: start.toDate(),
                                    endDate: end.toDate(),
                                    ranges: {
                                    Today: [moment().toDate(), moment().toDate()],
                                    Yesterday: [
                                        moment().subtract(1, 'days').toDate(),
                                        moment().subtract(1, 'days').toDate(),
                                    ],
                                    'Last 7 Days': [
                                        moment().subtract(6, 'days').toDate(),
                                        moment().toDate(),
                                    ],
                                    'Last 30 Days': [
                                        moment().subtract(29, 'days').toDate(),
                                        moment().toDate(),
                                    ],
                                    'This Month': [
                                        moment().startOf('month').toDate(),
                                        moment().endOf('month').toDate(),
                                    ],
                                    'Last Month': [
                                        moment().subtract(1, 'month').startOf('month').toDate(),
                                        moment().subtract(1, 'month').endOf('month').toDate(),
                                    ],
                                    },
                                }}
                                onCallback={handleCallback}
                            >
                                <input type='text' className='form-control wv-225 ms-3'/>
                            </DateRangePicker>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body className='py-0'>
                    <ApexCharts options={chartRequest} series={chartRequest.series} type="bar" height={325} />
                </Card.Body>
            </Card>
        </>
    )
}
export default Request