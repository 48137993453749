import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from 'formik';
import { APIS } from "../../App";
import Layout from '../../layout/Layout';
import * as Yup from 'yup'
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { Tree } from "rsuite";

const QuotesAdd = () => {
    const params = useParams()
    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [loading, setLoading] = useState(false)
    const token = Cookies.get("fmljwt");
    useEffect(() => {
        getSingleQuateData()
    }, [])
    const getSingleQuateData = async () => {
        try {
            const result = await APIS.post(`/getSinglequotes/${params.id}`, {}, { headers: { authorization: `${token}` } })
            setData(result?.data?.data[0])
        } catch (error) {
            console.log(error)
        }
    }
    const initialValues = {
        en: data.en || '',
        bn: data.bn || '',
        hi: data.hi || '',
        mr: data.mr || '',
        gu: data.gu || '',
        ta: data.ta || '',
        te: data.te || '',
        kn: data.kn || '',
        pa: data.pa || '',
        or: data.or || '',
        ml: data.ml || '',
        status: data.status || 0
    }
    const validationSchema = Yup.object({
        en: Yup.string().required("English Quotes field is requierd"),
        bn: Yup.string().required("Bengali Quotes field is requierd"),
        hi: Yup.string().required("Marathi Quotes field is requierd"),
        mr: Yup.string().required("Marathi Quotes field is requierd"),
        gu: Yup.string().required("Gujarati Quotes field is requierd"),
        ta: Yup.string().required("Tamil Quotes field is requierd"),
        te: Yup.string().required("Telugu Quotes field is requierd"),
        kn: Yup.string().required("Kannada Quotes field is requierd"),
        pa: Yup.string().required("Panjab Quotes field is requierd"),
        or: Yup.string().required("Odisha Quotes field is requierd"),
        ml: Yup.string().required("Malayalam Quotes field is requierd")
    })

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: async (values) => {

            if (params.id) {
                try {
                    setLoading(true);

                    const result = await APIS.post(`/editQuotedata/${params.id}`, values, { headers: { authorization: `${token}` } })
                    if (result?.data?.status) {
                        toast.success(result?.data?.response_msg)
                        navigate(`/quotes/view/${params.id}`)
                    } else {
                        toast.error(result?.data?.response_msg)
                    }
                } catch (error) {
                    console.log(error)
                } finally {
                    setLoading(false);
                }
            } else {

                try {
                    setLoading(true);
                    const result = await APIS.post("/storeQuotes", values, { headers: { authorization: `${token}` } })
                    if (result?.data?.status) {
                        navigate('/quotes')
                        toast.success(result?.data?.response_msg)

                    } else {
                        toast.error(result?.data?.response_msg)
                    }
                } catch (error) {
                    console.log(error)
                } finally {
                    setLoading(false);
                }
            }
        }
    });
    return (
        <Layout sidebar={true}>
            {loading && <div className="loader " ></div>}

            <div className="page-heading">
                <h3>{`Quotes ${params.id ? "Edit" : "Add"}`}</h3>
                <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                    <Breadcrumb.Item >
                        <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <Link to="/quotes">Quotes</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{`Quotes ${params.id ? "Edit" : "Add"}`}</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="page-content">
                <Form noValidate onSubmit={formik.handleSubmit}>
                    <Card className="mb-4">
                        <Card.Body>
                            <Row>
                                <Col md={6}>
                                    <Form.Label>English Quotes</Form.Label>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        name="en"
                                        value={formik.values.en}
                                        onChange={formik.handleChange}
                                        className="my-2"
                                        required
                                    />
                                    {formik.errors.en && formik.touched.en && <div className="text-danger" >
                                        {formik.errors.en}
                                    </div>}
                                </Col>

                                <Col md={6}>
                                    <Form.Label>Hindi Quotes</Form.Label>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        name="hi"
                                        className="my-2"
                                        value={formik.values.hi}
                                        onChange={formik.handleChange}
                                        required
                                    />
                                    {formik.errors.hi && formik.touched.hi && <div className="text-danger" >
                                        {formik.errors.hi}
                                    </div>}
                                </Col>

                                <Col md={6}>
                                    <Form.Label>Marathi Quotes</Form.Label>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        name="mr"
                                        className="my-2"
                                        value={formik.values.mr}
                                        onChange={formik.handleChange}
                                        required
                                    />
                                    {formik.errors.mr && formik.touched.mr && <div className="text-danger" >
                                        {formik.errors.mr}
                                    </div>}
                                </Col>

                                <Col md={6}>
                                    <Form.Label>Gujarati Quotes</Form.Label>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        name="gu"
                                        className="my-2"
                                        value={formik.values.gu}
                                        onChange={formik.handleChange}
                                        required
                                    />
                                    {formik.errors.gu && formik.touched.gu && <div className="text-danger" >
                                        {formik.errors.gu}
                                    </div>}
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Tamil Quotes</Form.Label>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        name="ta"
                                        className="my-2"
                                        value={formik.values.ta}
                                        onChange={formik.handleChange}
                                        required
                                    />
                                    {formik.errors.ta && formik.touched.ta && <div className="text-danger" >
                                        {formik.errors.ta}
                                    </div>}
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Telugu Quotes</Form.Label>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        name="te"
                                        className="my-2"
                                        value={formik.values.te}
                                        onChange={formik.handleChange}
                                        required
                                    />
                                    {formik.errors.te && formik.touched.te && <div className="text-danger" >
                                        {formik.errors.te}
                                    </div>}
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Kannada Quotes</Form.Label>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        name="kn"
                                        className="my-2"
                                        value={formik.values.kn}
                                        onChange={formik.handleChange}
                                        required
                                    />
                                    {formik.errors.kn && formik.touched.kn && <div className="text-danger" >
                                        {formik.errors.kn}
                                    </div>}
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Bengali Quotes</Form.Label>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        name="bn"
                                        className="my-2"
                                        value={formik.values.bn}
                                        onChange={formik.handleChange}
                                        required
                                    />
                                    {formik.errors.bn && formik.touched.bn && <div className="text-danger" >
                                        {formik.errors.bn}
                                    </div>}
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Panjab Quotes</Form.Label>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        name="pa"
                                        className="my-2"
                                        value={formik.values.pa}
                                        onChange={formik.handleChange}
                                        required
                                    />
                                    {formik.errors.pa && formik.touched.pa && <div className="text-danger" >
                                        {formik.errors.pa}
                                    </div>}
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Odisha Quotes</Form.Label>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        name="or"
                                        className="my-2"
                                        value={formik.values.or}
                                        onChange={formik.handleChange}
                                        required
                                    />
                                    {formik.errors.or && formik.touched.or && <div className="text-danger" >
                                        {formik.errors.or}
                                    </div>}
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Malayalam Quotes</Form.Label>
                                    <Form.Control
                                        type="text"
                                        as="textarea"
                                        name="ml"
                                        className="my-2"
                                        value={formik.values.ml}
                                        onChange={formik.handleChange}
                                        required
                                    />
                                    {formik.errors.ml && formik.touched.ml && <div className="text-danger" >
                                        {formik.errors.ml}
                                    </div>}
                                </Col>

                                <Col md={2}>
                                    <Form.Label htmlFor="status" className="d-block mb-2">
                                        Status
                                    </Form.Label>
                                    <Form.Check onChange={(e) => { formik.setFieldValue("status", e.target.checked ? 1 : 0) }} name="status" checked={formik.values.status} type="switch" />
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button variant="primary" type="submit" className="me-3">Save</Button>
                            <Link to={`${params.id ? `/quotes/view/${params.id}` : `/quotes`}`}>
                                <Button variant="secondary">Cancel</Button>
                            </Link>
                        </Card.Footer>
                    </Card>
                </Form>
            </div>
        </Layout>
    )
}

export default QuotesAdd