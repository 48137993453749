import React, { useContext, useEffect, useState } from 'react'
import Layout from '../../layout/Layout'
import { Dropdown, SelectPicker } from 'rsuite'
import { Button, Card, Form, InputGroup, Modal, Table } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import Pagination from "rc-pagination";
import moment from 'moment'
import { APIS, AuthContext } from '../../App'
import Cookies from 'js-cookie'
import axios from 'axios'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import Select from "react-select"
import { toast } from "react-toastify";
import ApexCharts from 'react-apexcharts';

const FailData = () => {
    const PageSize = [100, 500, 1000].map((val, index) => ({ label: val, value: val }));
    const deviceType = ["All", "ios", "android"].map((val, index) => ({ label: val, value: val }));
    const statusLabel = {
        0: "Pending/Fail",
        1: "App Success",
        3: "First Priority Source Success",
        2: "Second Priority Source Success",
        4: "Script Success"
    }
    const token = Cookies.get("fmljwt");
    const [size, setSize] = useState(100);
    const [loading, setIsLoading] = useState(true)
    const [current, setCurrent] = useState(1);
    const [totalFailnumber, setTotalFailnumber] = useState(0);
    const [allFailData, setAllFailData] = useState([])
    const [failStatus, setFailStatus] = useState([])
    const [inputNumber, setInputNumber] = useState("")
    const [searchedData, setSearchedData] = useState()
    const [show, setShow] = useState(false);
    const [graphValue, setGraphValue] = useState({ date: [], value: [] });
    const [dateTimezone, setDateTimezone] = useState("daily");
    const [selectedDeviceType, setSelectedDeviceType] = useState("All");
    const [state, setState] = useState({
        start: moment().subtract(6, 'days'),
        end: moment(),
    });
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const navigate = useNavigate()
    const paginationData = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize);
    };
    const { start, end } = state;
    useEffect(() => {
        getFailData()
    }, [state, current, size, dateTimezone, selectedDeviceType])
    useEffect(() => {
        getFailStatus()
    }, [])

    useEffect(() => {
        let sum = 0;
        allFailData?.data?.forEach((item, index) => {
            sum += item?.totalCount || 0;
        });
        // setTotalFailnumber(sum);
    }, [allFailData]);

    const getFailStatus = async (id) => {
        // setIsLoading(true);
        try {
            const result = await APIS.get("/get_fail_status_with_count", { headers: { authorization: `${token}` } })
            let status = [];
            result?.data?.data?.map((item) => {
                [0, 1, 2, 3, 4]?.includes(item?.status) && status.push({ value: item?.status, label: statusLabel[item?.status] + " - " + item?.totalCount })
            })
            setFailStatus(status)
        } catch (error) {
            console.log(error)
        } finally {
            // setIsLoading(false);
        }
    }

    const getFailData = async () => {

        setIsLoading(true);
        const Form = new FormData()
        Form.append("page", current)
        Form.append("pageSize", size)
        Form.append("type", dateTimezone)
        selectedDeviceType != "All" && Form.append("deviceType", selectedDeviceType)
        Form.append("startdate", start.format('YYYY-MM-DD'))
        Form.append("enddate", end.format('YYYY-MM-DD'))
        try {
            const result = await APIS.post("/findFailedData", Form, { headers: { authorization: `${token}` } })
            setAllFailData(result?.data)

            const newGraphValue = { date: [], value: [] };
            result?.data?.data?.forEach(item => {
                newGraphValue.date.push(dateTimezone != "daily" ? moment(item?.dayStart)?.format('DD/MM') + " - " + moment(item?.dayEnd)?.format('DD/MM') : moment(item?.dayStart)?.format('DD/MM/YY'));
                newGraphValue.value.push(item?.totalCount);
            });
            setGraphValue(newGraphValue);
            if (result?.data?.totalCount) {
                setTotalFailnumber(result?.data?.totalCount[0]?.totalCount)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    const handleCallback = (start, end) => {
        setState({ start, end });
    };

    const label = start.format('DD-MM-YYYY') + ' - ' + end.format('DD-MM-YYYY');
    const handleSearchFailData = async () => {
        const Form = new FormData()
        Form.append("reg_number", inputNumber)
        try {
            const result = await APIS.post("/get_reg_data", Form, { headers: { authorization: `${token}` } })
            if (result?.data?.status == 200 && result?.data?.data?.length > 0) {
                setSearchedData(result?.data?.data)
                handleShow()
            } else {
                setSearchedData([])
                toast?.error("Data Not Found")
            }
        } catch (error) {
            console.log(error)
        } finally {
            // setIsLoading(false);
        }
    }

    const renderIconButton = (props, ref) => {
        return (
            <Button {...props} ref={ref} className="px-3 py-2"><i className='bx bx-info-circle' ></i></Button>
        );
    };

    const renderIconButton2 = (props, ref) => {
        return (
            <Button {...props} ref={ref} className="btn btn-icon btn-transparent btn-success"><i className="bx bx-info-circle"></i></Button>
        );
    };

    const { theme, setTheme } = useContext(AuthContext)
    const redcolor = "#FF4560"
    const [chartFailData, setChartFailData] = useState({
        series: [
            {
                name: 'Fail Data',
                data: graphValue?.value
            }
        ],
        chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
                show: false
            },
            zoom: {
                enabled: false
            },
            height: 350
        },
        colors: [redcolor],
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '15%',
                endingShape: 'rounded',
                borderRadius: 4,
                dataLabels: {
                    total: {
                        enabled: true,
                        offsetX: 0,
                        style: {
                            color: `${theme == "dark" ? '#b2b2b2' : '#6C737D'}`,
                            fontSize: '12px',
                            fontFamily: 'Maven Pro',
                            fontWeight: 500,
                        }
                    }
                }
            }
        },
        legend: {
            show: false,
        },
        states: {
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                }
            },
            active: {
                filter: {
                    type: 'none',
                    value: 0,
                }
            },
        },
        grid: {
            borderColor: `${theme == "dark" ? '#2e2d32' : '#DEF4EB'}`,
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
        },
        xaxis: {
            categories: graphValue?.date,
            labels: {
                style: {
                    colors: "#6C737D",
                    fontSize: '14px',
                    fontFamily: 'Maven Pro',
                    fontWeight: 500,
                },
            }
        },
        yaxis: {
            labels: {
                style: {
                    colors: "#6C737D",
                    fontSize: '14px',
                    fontFamily: 'Maven Pro',
                    fontWeight: 500,
                },
                formatter: (val) => {
                    return val
                    // return val / 10 + 'L'
                }
            }
        },
        fill: {
            opacity: 1
        },
        tooltip: {
            enabled: true,
            shared: true,
            intersect: false,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const partialData = series && series[0][dataPointIndex];
                const seriesName = w.globals.labels[dataPointIndex];
                const cyellow = w.config.colors[0];
                return `
                    <div class="tooltipbox">
                        <h6>${seriesName}</h6>
                        <div class="tooltipbody">
                            <p><span class="legend" style="background-color:${cyellow}"></span> Fail Data : <span class="tooltiptext">&nbsp;${partialData}</span></p>
                        </div>
                    </div>
                `;
            }
        },
    });

    useEffect(() => {
        setChartFailData((prevState) => ({
            ...prevState,
            plotOptions: {
                bar: {
                    dataLabels: {
                        total: {
                            style: {
                                color: `${theme == "dark" ? '#b2b2b2' : '#6C737D'}`,
                            }
                        }
                    }
                }
            },
            grid: {
                borderColor: `${theme == "dark" ? '#2e2d32' : '#DEF4EB'}`,
            },
        }));
    }, [theme]);

    useEffect(() => {
        setChartFailData((prevState) => ({
            ...prevState,
            series: [
                {
                    name: 'Fail Data',
                    data: graphValue?.value?.reverse()
                }
            ],
            xaxis: {
                categories: graphValue?.date?.reverse(),
                labels: {
                    style: {
                        colors: "#6C737D",
                        fontSize: '14px',
                        fontFamily: 'Maven Pro',
                        fontWeight: 500,
                    },
                }
            }
        }));
    }, [start, end, graphValue]);
    const dateOptions = ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly'].map(
        item => ({ label: item, value: item?.toLowerCase() })
    );
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">RC Fail Data <span></span></h3>
                <div className="page-heading-right flex-nowrap">
                    <div className="inputgroup my-1">
                        <Form.Control type='text' onChange={(e) => setInputNumber(e.target.value)} placeholder='Search by fail data' className='wv-225'></Form.Control>
                        <i className="bx bx-search" onClick={handleSearchFailData} />
                    </div>
                    <SelectPicker
                        cleanable={false}
                        data={deviceType}
                        searchable={false}
                        onChange={(e) => setSelectedDeviceType(e)}
                        value={selectedDeviceType}
                        placement="bottomEnd"
                        placeholder="Select DeviceType"
                        className="wv-150 my-1 ms-3"
                    />
                    <SelectPicker
                        cleanable={false}
                        data={dateOptions}
                        searchable={false}
                        value={dateTimezone}
                        onChange={(e) => setDateTimezone(e)}
                        placement="bottomEnd"
                        placeholder="Select Date"
                        className="wv-150 my-1 ms-3"
                    />
                    <DateRangePicker
                        initialSettings={{
                            startDate: start.toDate(),
                            endDate: end.toDate(),
                            ranges: {
                                Today: [moment().toDate(), moment().toDate()],
                                Yesterday: [
                                    moment().subtract(1, 'days').toDate(),
                                    moment().subtract(1, 'days').toDate(),
                                ],
                                'Last 7 Days': [
                                    moment().subtract(6, 'days').toDate(),
                                    moment().toDate(),
                                ],
                                'Last 30 Days': [
                                    moment().subtract(29, 'days').toDate(),
                                    moment().toDate(),
                                ],
                                'This Month': [
                                    moment().startOf('month').toDate(),
                                    moment().toDate(),
                                ],
                                'Last Month': [
                                    moment().subtract(1, 'month').startOf('month').toDate(),
                                    moment().subtract(1, 'month').endOf('month').toDate(),
                                ],
                            },
                        }}
                        onCallback={handleCallback}
                    >
                        <div
                            id="reportrange"
                            className="form-control wv-225 my-1 ms-3 "
                            style={{
                                cursor: 'pointer',
                            }}
                        >
                            <i className="fa fa-calendar"></i>&nbsp;
                            <span>
                                {label}
                            </span> <i className="fa fa-caret-down"></i>
                        </div>
                    </DateRangePicker>
                    <SelectPicker
                        cleanable={false}
                        data={PageSize}
                        searchable={false}
                        value={size}
                        placement="bottomEnd"
                        className="wv-100 my-1 ms-3"
                        onChange={(e) => {
                            setSize(Number(e));
                            setCurrent(1);
                        }}
                    />
                    <Dropdown renderToggle={renderIconButton} placement="bottomEnd" className="my-1 ms-3">
                        {failStatus?.map((item, index) => { return (<Dropdown.Item>{item?.label}</Dropdown.Item>) })}
                    </Dropdown>
                </div>
            </div>
            <div className="page-content">
                <Card className="radiustop-0">
                    <Card.Body className="border-bottom py-0 px-1">
                        <ApexCharts options={chartFailData} series={chartFailData.series} type="bar" height={325} />
                    </Card.Body>
                    <Card.Body>
                        {loading && <div className="loader table-loader"></div>}
                        <Table className="table-bg-primary" bordered striped responsive>
                            <thead>
                                <tr>
                                    <th width="5%" className="text-center">No</th>
                                    <th width="22%" className="text-center">Date</th>
                                    <th width="21%" className="text-center">Total Fail Record</th>
                                    <th width="21%" className="text-center">Success Record</th>
                                    <th width="21%" className="text-center">Pending Record</th>
                                    <th width="10%" className="text-center">Show Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allFailData?.data?.length > 0 ? allFailData?.data?.map((item, index) => {
                                    return (<tr key={index} >
                                        <td className='text-center'>{(current === 1) ? index + 1 : current * size + index + 1 - size}</td>
                                        <td className="text-center" onClick={() => navigate(`/faildataview/${dateTimezone !== "daily" ? item?.dayStart + " - " + item?.dayEnd : item?.dayStart}`)}>
                                            {item?.dayStart && item?.dayEnd && (
                                                dateTimezone !== "daily"
                                                    ? `${moment(item?.dayStart).format("DD-MM-YYYY")} - ${moment(item?.dayEnd).format("DD-MM-YYYY")}`
                                                    : moment(item?.dayStart).format("DD-MM-YYYY")
                                            )}
                                        </td>
                                        <td className="text-center">{item?.totalCount}</td>
                                        <td className="text-center">{`${item?.successCount}   [${item?.successCount - item?.scriptCount} + ${item?.scriptCount}]`}</td>
                                        <td className="text-center">{item?.totalCount - item?.successCount}</td>
                                        <td className='text-center'>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                <Link to={`/faildataview/${dateTimezone !== "daily" ? item?.dayStart + " - " + item?.dayEnd : item?.dayStart}`} className="btn btn-icon btn-transparent btn-danger">
                                                    <i className='bx bx-receipt'></i>
                                                </Link>
                                                <span className='border-start py-2 mx-2'></span>
                                                {/* <Link to={`/statusview/${item?.date}`} className="btn btn-icon btn-transparent btn-success">
                                                    <i className="bx bx-info-circle"></i>
                                                </Link> */}
                                                <Dropdown renderToggle={renderIconButton2} placement="bottomEnd">
                                                    <Dropdown.Item>{"Pending/Fail - " + (item?.totalCount - item?.successCount)}</Dropdown.Item>
                                                    <Dropdown.Item>{"App Success - " + item?.appSuccessCount}</Dropdown.Item>
                                                    <Dropdown.Item>{"First Priority Source Success - " + item?.firstPriorityCount}</Dropdown.Item>
                                                    <Dropdown.Item>{"Second Priority Source Success - " + item?.secondPriorityCount}</Dropdown.Item>
                                                    <Dropdown.Item>{"Script Success - " + item?.scriptCount}</Dropdown.Item>
                                                    {/* {failStatus?.map((item, index) => { return (<Dropdown.Item>{item?.label}</Dropdown.Item>) })} */}
                                                </Dropdown>
                                            </div>
                                        </td>
                                    </tr>)
                                }) :

                                    <tr>
                                        <td colSpan="100%" className="p-0">
                                            <div className='no-found'>
                                                <img src="../../not-found/image.svg" />
                                                <p>No Found RC Fail Data</p>
                                            </div>
                                        </td>
                                    </tr>}
                            </tbody>
                        </Table>
                        {allFailData?.totalCountRecord > size && <div className="pagination-custom">
                            <Pagination
                                showTitle={false}
                                className="pagination-data"
                                current={current}
                                onChange={paginationData}
                                total={allFailData?.totalCountRecord}
                                pageSize={size}
                                showSizeChanger={false}
                            />
                        </div>}
                    </Card.Body>
                </Card>
            </div>
            <Modal show={show} size='lg' onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>RC Fail Data - ( {inputNumber} )</Modal.Title>
                </Modal.Header>
                < Modal.Body  >
                    <Table className="table-bg-primary" bordered striped responsive>
                        <thead>
                            <tr>
                                <th width="20%" className='text-center'>Date</th>
                                <th width="80%" >Fail Reason</th>
                            </tr>
                        </thead>
                        <tbody>
                            {searchedData?.length > 0 && searchedData?.map((item, index) => {
                                return (<tr key={index}>
                                    <td width="20%" className='text-center'>{moment(item?.date)?.format("DD-MM-YYYY")}</td>
                                    <td width="80%"><ul>{item?.fail_reason ? item?.fail_reason?.split(",")?.map((el) => {
                                        return (
                                            <li>{el}</li>
                                        )
                                    }) : "-"}</ul></td>
                                </tr>)
                            })}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal>
        </Layout >
    )
}

export default FailData