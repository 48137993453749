import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, Row } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import Cookies from "js-cookie";
import Layout from '../../layout/Layout';
import { Link, useLocation } from "react-router-dom";
import { APIS } from "../../App";
import moment from "moment";

const dataType = ['Eugenia', 'Bryan', 'Linda', 'Nancy', 'Lloyd', 'Alice', 'Julia', 'Albert'].map(
    item => ({ label: item, value: item })
);

const ChallanInformationView = () => {
    const token = Cookies.get("fmljwt");

    const [loading, setIsLoading] = useState(false)
    const [challanDetails, setChallanDetails] = useState([])
    const [fileterData, setFileterData] = useState(challanDetails)
    const [activeFilter, setActiveFilter] = useState("all");

    const { state } = useLocation();
    const getChallanDetails = async () => {
        try {
            setIsLoading(true)
            const formData = new FormData()
            formData.append("reg_no", state)
            const results = await APIS.post('/viewChallanDetails', formData, { headers: { Authorization: `${token}` } })
            if (results?.data?.status) {
                setChallanDetails(results?.data?.data)
                setFileterData(results?.data?.data)
            }
        } catch (error) {
            console.log('error', error)
        } finally {
            setIsLoading(false)
        }
    }

    const handleFilterChallan = (value) => {
        let filteredChallans;
        if (value === 'all') {
            setFileterData(challanDetails);
            setActiveFilter(value);
            return;
        } else if (value === 'paid') {
            filteredChallans = challanDetails?.filter(item =>
                item.challan_status === 'Cash' || item.challan_status === 'Disposed'
            );
        } else if (value === 'unpaid') {
            filteredChallans = challanDetails?.filter(item =>
                item.challan_status !== 'Cash' && item.challan_status !== 'Disposed'
            );
        }
        setFileterData(filteredChallans);
        setActiveFilter(value);
    };

    useEffect(() => {
        state && getChallanDetails()
    }, [state])
    return (
        <Layout sidebar={true}>
            <div className="page-heading backbtn">
                <h3>
                    <Link to="/challan-information" className='btn btn-transparent btn-icon me-2'>
                        <i className='bx bx-chevron-left' ></i>
                    </Link>
                    eChallan <span>{state}</span>
                </h3>
                <div className="page-heading-right">
                    <Button variant={activeFilter == "all" ? "primary" : "outline-primary"} className="rounded-pill wv-100" onClick={() => handleFilterChallan('all')}>All</Button>
                    <Button variant={activeFilter == "paid" ? "success" : "outline-success"} className="rounded-pill wv-100 ms-3" onClick={() => handleFilterChallan('paid')}>Paid</Button>
                    <Button variant={activeFilter == "unpaid" ? "danger" : "outline-danger"} className="rounded-pill wv-100 ms-3" onClick={() => handleFilterChallan('unpaid')}>Unpaid</Button>
                </div>
            </div>
            <div className='page-content'>
                <Card className="radiustop-0">
                    <Card.Body>
                        <Row className="g-4">
                            {fileterData?.length > 0 && fileterData?.map((item, index) => {
                                return (<Col sm={12} key={index}>
                                    <div className={`echallan-view ${(item.challan_status === 'Cash' || item.challan_status === 'Disposed') ? "paid" : "unpaid"}`}>
                                        <div className="echallan-view-header">
                                            <div className="echallan-view-header-l">
                                                <p>{index + 1}</p><span>Challan</span><h6>#{item?.challan_no}</h6>
                                            </div>
                                            <p>{(item.challan_status === 'Cash' || item.challan_status === 'Disposed') ? "Paid" : "Unpaid"}</p>
                                        </div>
                                        <div className="echallan-view-body">
                                            <Row>
                                                <Col lg={2}>
                                                    <div>
                                                        <span className={(item.challan_status === 'Cash' || item.challan_status === 'Disposed') ? "green" : "red"}>₹{item?.challan_amount}</span><p>Challan Amount</p>
                                                    </div>
                                                </Col>
                                                <Col lg={2}>
                                                    <div>
                                                        <span>{moment(item?.challan_date)?.format('DD MMM, YYYY h:mm A')}</span><p>Challan Date</p>
                                                    </div>
                                                </Col>
                                                <Col lg={2}>
                                                    <div>
                                                        <span>{item?.state}</span><p>State</p>
                                                    </div>
                                                </Col>
                                                <Col lg={2}>
                                                    <div>
                                                        <span>{item?.violator_name}</span><p>Violator Name</p>
                                                    </div>
                                                </Col>
                                                <Col lg={2}>
                                                    <div>
                                                        <span>{item?.payment_source}</span><p>Payment Source</p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                </Col>)
                            })}
                        </Row>
                      
                        {fileterData?.length <= 0 && 
                                    <div className="d-flex justify-content-center">
                                        <div className='no-found'>
                                            <img src="../../not-found/image.svg" />
                                            <p>No Found Challan Information</p>
                                        </div>
                                    </div>
                                }
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default ChallanInformationView