import React, { useState } from "react";
import { Card } from 'react-bootstrap';
import ApexCharts from 'react-apexcharts'
import { SelectPicker } from 'rsuite';

const data = ['Eugenia', 'Bryan', 'Linda', 'Nancy', 'Lloyd', 'Alice', 'Julia', 'Albert'].map(
    item => ({ label: item, value: item })
);

const VehicleFuelType = () => {
    
    const petrol = "#008FFB"
    const diesel = "#00D8B6"
    const cng = "#FEB019"
    const lpg = "#FF4560"
    const electric = "#775DD0"
    const hybrid = "#FF45F8"

    const [chartFuelType, setChartFuelType] = useState({
        series: [120, 120, 60, 72, 71, 96],
        options: {
            chart: {
                type: 'pie',
            },
            labels: ['Petrol', 'Diesel', 'CNG', 'LPG', 'Electric', 'Hybrid'],
            colors: [petrol, diesel, cng, lpg, electric, hybrid],
            legend: {
                show: true,
                position: 'bottom',
                fontFamily: 'Maven Pro',
                fontSize: '13px',
                fontWeight: 500,
                labels: {
                    colors: "#6C737D",
                },
                markers: {
                    width: 0,
                    height: 0,
                },
                customLegendItems: [
                    '<img src="../dashboard/petrol.svg" alt="Bike" /> PETROL',
                    '<img src="../dashboard/diesel.svg" alt="Car" /> DIESEL',
                    '<img src="../dashboard/cng.svg" alt="Bus" /> CNG',
                    '<img src="../dashboard/lpg.svg" alt="E-Car" /> LPG',
                    '<img src="../dashboard/electric.svg" alt="Truck" /> ELECTRIC',
                    '<img src="../dashboard/hybrid.svg" alt="Truck" /> HYBRID',
                ],
                onItemClick: {
                    toggleDataSeries: false
                },
                onItemHover: {
                    highlightDataSeries: false
                },
            },
            states: {
                hover: {
                    filter: {
                        type: 'none',
                        value: 0,
                    }
                },
                active: {
                    filter: {
                        type: 'none',
                        value: 0,
                    }
                },
            },
            tooltip: {
                enabled: true,
                shared: true,
                intersect: false,
                custom: function({ series, seriesIndex, dataPointIndex, w }) {
                    const seriesName = w.globals.labels[seriesIndex];
                    const color = w.config.colors[seriesIndex];
                    return `
                        <div class="tooltipbox">
                            <h6>Vehicle Fuel Type</h6>
                            <div class="tooltipbody">
                                <p>
                                    <span class="legend" style="background-color:${color}"></span>${seriesName} : <span class="tooltiptext">&nbsp;${series&&series[seriesIndex]}</span>
                                </p>
                            </div>
                        </div>
                    `;
                }
            },
            dataLabels: {
                enable: true,
                textAnchor: 'middle',
                style: {
                    fontFamily: 'Maven Pro',
                    fontSize: '14px',
                    fontWeight: '500',
                },
                dropShadow: {
                    enabled: false,
                },
                formatter: function (val, opts) {
                    return opts.w.config.series[opts.seriesIndex]+"L"
                },
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        decimalsInFloat: 0,
                        offset: -10,
                    }
                }
            }
        }
    });
    return(
        <Card>
            <Card.Header className='pb-0'>
                <div className="chart-title">
                    <div>
                        <h4>Vehicle Fuel Type</h4>
                        <p>Total Vehicle : 6,02,584,690</p>
                    </div>
                    <div className='chartfilter'>
                        <SelectPicker
                            cleanable={false}
                            data={data}
                            size="md"
                            searchable={false}
                            defaultValue={""}
                            placeholder="SELECT STATE"
                            placement="bottomEnd"
                            className="wv-175 ms-3"
                        />
                    </div>
                </div>
            </Card.Header>
            <Card.Body className="d-flex justify-content-center">
                <ApexCharts options={chartFuelType.options} series={chartFuelType.series} type="pie" width={450} />
            </Card.Body>
        </Card>
    )
}
export default VehicleFuelType