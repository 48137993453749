import dayjs from "dayjs";
import Cookies from "js-cookie";
import Pagination from "rc-pagination";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, InputGroup, Modal, Table } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import { API, APIS } from "../../App";
import Layout from '../../layout/Layout';
import { Link } from "react-router-dom";

const dateOptions = [10, 50, 100, 150, 200].map(
    item => ({ label: item, value: item })
);
  
const Token = () => {
    const [size, setSize] = useState(10);
    const [current, setCurrent] = useState(1);
    const [loading, setIsLoading] = useState(false)

    const paginationData = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize);
    };

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">Token <span>123</span></h3>
                <div className="page-heading-right">
                    <div className="inputgroup  my-1">
                        <Form.Control
                            type="text"
                            name="searchvalue"
                            placeholder="Search by token, email, mobile no..."
                            className="wv-325 ms-3"
                        />
                        <i className="bx bx-search"/>
                    </div>

                    <SelectPicker
                        cleanable={false}
                        data={dateOptions}
                        searchable={false}
                        value={size}
                        className="wv-100 my-1 ms-3"
                    />

                </div>
            </div>
            <div className="page-content">
                <Card className="radiustop-0">
                    <Card.Body>
                        {/* {loading && <div className="loader table-loader"></div>} */}
                        <Table className="table-bg-primary" bordered responsive striped>
                            <thead>
                                <tr>
                                    <th width="5%" className="text-center">No</th>
                                    <th width="15%">Mobile No.</th>
                                    <th width="25%">Email Address</th>
                                    <th width="10%">Record Id</th>
                                    <th width="10%">Device Id</th>
                                    <th width="25%">Token Name</th>
                                    <th width="10%" className="text-center">JSON</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='text-center'>1</td>
                                    <td>+1 987-654-0321</td>
                                    <td>MckenzieKessler@rhyta.com</td>
                                    <td>AS14H8966</td>
                                    <td>AS14H8966</td>
                                    <td>Mckenzie Kessler</td>
                                    <td className="text-center">
                                        <Link to="/token" className="btn btn-icon btn-transparent btn-primary">
                                            <i className='bx bx-show'></i>
                                        </Link>
                                    </td>
                                </tr>
                                {/* <tr>
                                    <td colSpan="100%" className="p-0">
                                        <div className='no-found'>
                                            <img src="../../not-found/image.svg" />
                                            <p>No Found Token</p>
                                        </div>
                                    </td>
                                </tr> */}
                            </tbody>
                        </Table>
                        <div className="pagination-custom">
                            <Pagination
                                showTitle={false}
                                className="pagination-data"
                                onChange={paginationData}
                                total={100}
                                current={current}
                                pageSize={size}
                                showSizeChanger={false}
                            />
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default Token