import React, { useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API, APIS } from "../../App";
import Switch from 'react-switch';
import Cookies from "js-cookie";
import Layout from '../../layout/Layout';

const AppUpdateAdd = () => {
  const token = Cookies.get("fmljwt");
  const [validated, setvalidated] = useState(false)
  const [start_io_ads_enable, setstart_io_ads_enable] = useState(0)
  const [affilation_program_enable, setaffilation_program_enable] = useState(0)
  const navigate = useNavigate()
  const [Data, setData] = useState([{
    title: "",
    version_code: "",
    current_version: "",
    package_name: "",
    start_io_ads_enable: "",
    affilation_program_enable: "",
  }])

  const SaveData = async (e) => {
    setData({ ...Data, [e.target.name]: e.target.value })
  }

  const Submite = async () => {
    if (Data.title == undefined || Data.version_code == undefined || Data.current_version == undefined || Data.package_name == undefined) {
      setvalidated(true)
    } else {
      const Form = new FormData()
      Form.append('title', Data.title)
      Form.append('version_code', Data.version_code)
      Form.append('current_version', Data.current_version)
      Form.append('package_name', Data.package_name)
      Form.append('start_io_ads_enable', start_io_ads_enable)
      Form.append('affilation_program_enable', affilation_program_enable)
      const Result = await APIS.post(`/storeAppData`, Form, { headers: { authorization: `${token}` } })
      if (Result) {
        toast.success("Data Saved successfully");
        navigate(`/app-update`)
      }
    }
  }

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>App Update Add</h3>
        <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
          <Breadcrumb.Item >
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item >
            <Link to="/app-update">App Update</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>App Update Add</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="page-content">
        <Form noValidate validated={validated}>
          <Card className="mb-4 radiustop-0">
            <Card.Body>
              <Row>
                <Col md={4}>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="text"
                    name="title"
                    onChange={SaveData}
                    className="my-2"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Title Field Is Require
                  </Form.Control.Feedback>
                </Col>

                <Col md={4}>
                  <Form.Label>Minimum Version Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="version_code"
                    onChange={SaveData}
                    className="my-2"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Minimum Version Code Field Is Require
                  </Form.Control.Feedback>
                </Col>

                <Col md={4}>
                  <Form.Label>Current Live Version</Form.Label>
                  <Form.Control
                    type="text"
                    name="current_version"
                    onChange={SaveData}
                    className="my-2"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Current Live Version Field Is Require
                  </Form.Control.Feedback>
                </Col>

                <Col md={4}>
                  <Form.Label>Package Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="package_name"
                    onChange={SaveData}
                    className="my-2"
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    Package Name Field Is Require
                  </Form.Control.Feedback>
                </Col>

                <Col md={2}>
                  <Form.Label htmlFor="status" className="d-block mb-2">
                    Start iO Ads
                  </Form.Label>
                  <Form.Check
                    type="switch"
                    name="start_io_ads_enable"
                    onChange={(e) => {
                      e.target.checked === true ? setstart_io_ads_enable(1) : setstart_io_ads_enable(0)
                    }}
                    checked={start_io_ads_enable === 1 ? true : false}
                  />
                </Col>

                <Col md={2}>
                  <Form.Label htmlFor="status" className="d-block mb-2">
                    Affiliation Program
                  </Form.Label>
                  <Form.Check
                    type="switch"
                    name="affilation_program_enable"
                    onChange={(e) => {
                      e.target.checked === true ? setaffilation_program_enable(1) : setaffilation_program_enable(0)
                    }}
                    checked={affilation_program_enable === 1 ? true : false}
                  />
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="text-end">
              <Button variant="primary" onClick={Submite} className="me-3">Save</Button>
              <Link to='/app-update'>
                <Button variant="secondary">Cancel</Button>
              </Link>
            </Card.Footer>
          </Card>
        </Form>
      </div>
    </Layout>
  )
}

export default AppUpdateAdd