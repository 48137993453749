import React, { useEffect, useState } from 'react'
import Layout from '../../layout/Layout'
import { SelectPicker } from 'rsuite'
import { Card, Form, Table } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import Pagination from 'rc-pagination'
import Cookies from 'js-cookie'
import axios from 'axios'
import moment from 'moment'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { APIS } from '../../App'


const StatusData = () => {
    const statusLabel = {
        0: "Pending/Fail",
        1:"App Success",
        2: "First Priority Source Success",
        3: "Second Priority Source Success",
        4:"Script Success"
    }
    const token = Cookies.get("fmljwt");
    const [size, setSize] = useState(10);
    const [loading, setIsLoading] = useState(false)
    const [current, setCurrent] = useState(1);
    const [allFailDataStatusList, setAllFailDataStatusList] = useState([])
    const [stateData, setStateData] = useState([])
    const [selectedStateCode, setSelectedStateCode] = useState("ALL")
    const [search, setSearch] = useState("")
    const params = useParams()


    useEffect(() => {
        getFailDataStatusList()
    }, [])


    const getFailDataStatusList = async () => {
        setIsLoading(true);
        const Form = new FormData()
        Form.append("registerNumberDate", params.id)
        try {
            const result = await APIS.post("/get_all_status_wise_count ", Form, { headers: { authorization: `${token}` } })
            setAllFailDataStatusList(result?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }
    return (
        <Layout sidebar={true}>
            <div className="page-heading backbtn">
                <h3>
                    <Link to="/faildata" className='btn btn-transparent btn-icon me-2'>
                        <i className='bx bx-chevron-left' ></i>
                    </Link> 
                    RC Fail Data <span> {moment(params.id).format("DD-MM-YYYY")}</span>
                </h3>
            </div>
            <div className="page-content">
                <Card className="radiustop-0">
                    <Card.Body>
                        {loading && <div className="loader table-loader"></div>}
                        <Table className="table-bg-primary" striped bordered responsive>
                            <thead>
                                <tr>
                                    <th width="5%" className="text-center">No</th>
                                    <th width="50%">Status</th>
                                    <th width="50%">Count</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allFailDataStatusList?.data?.length > 0 ? allFailDataStatusList?.data?.map((item, index) => {
                                    return ([0,1,2,3,4].includes(item?.status)&&<tr key={index}>
                                        <td className='text-center'>{index + 1}</td>
                                        <td>{statusLabel[item?.status]}</td>
                                        <td>{item?.count ? item?.count : "-"}</td>
                                    </tr>)
                                })
                                    :
                                    <tr>
                                        <td colSpan="100%" className="p-0">
                                            <div className='no-found'>
                                                <img src="../../not-found/image.svg" />
                                                <p>No Found RC Fail Data</p>
                                            </div>
                                        </td>
                                    </tr>}
                            </tbody>
                        </Table>

                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default StatusData