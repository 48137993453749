import React, { useContext, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Button, ButtonGroup, Card, Col, Form, Row, Table } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { toast } from "react-toastify";
import { API, APIS, AuthContext } from "../../App";
import Swal from "sweetalert2";
import Switch from 'react-switch';
import Layout from '../../layout/Layout';
import $ from "jquery";
import Cookies from "js-cookie";

var Page_array = [];
const ApipurchasePriority = () => {
  const token = Cookies.get("fmljwt");
  const permission = JSON?.parse(localStorage.getItem('permissions'))

  const [Data, setData] = useState([])
  const [PageHook, setPageHook] = useState([])
  const [loading, setloading] = useState(true)
  const GetData = async () => {
    const result = await APIS.post("/getApiPurchasePriority", {}, { headers: { authorization: `${token}` } })
    setData(result?.data?.data)
    PageGetData()
    setloading(false)
  }



  const PageGetData = async () => {
    var PageNumber = [10, 25, 50, 100]
    Page_array = []
    PageNumber.map((val, index) => {
      Page_array.push({ label: val, value: val })
    })
    setPageHook(Page_array)
  };

  const Togglechange = async (id, e, name) => {
    var status;
    if (name === "status") {
      status = e === true ? 1 : 0;
    }
    const Form = new FormData();
    Form.append("id", id);
    Form.append("status", status);
    let result
    result = await APIS.post("/apiPurchasePriorityStatusChange", Form, { headers: { authorization: `${token}` } });

    if (result) {
      toast.success("Status Update successfully");
      GetData();
    }
  };

  let count = 10
  let swalCountdownInterval
  const DeleteData = async (id) => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger btn-lg counter",
        cancelButton: "btn btn-primary btn-lg me-3",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        imageUrl: '../../icon/alert.svg',
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: "OK (10)",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        reverseButtons: true,
        didOpen: () => {
          $(".swal2-confirm").attr('disabled', true);
          swalCountdownInterval = setInterval(function () {
            count--
            if (count < 1) {
              $(".counter").text(`OK`)
              $(".swal2-confirm").attr('disabled', false);
              clearInterval(swalCountdownInterval)
            } else {
              $(".counter").text(`OK (${count})`)
            }
          }, 1000);
        }
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          await APIS.post(`/deleteApiPurchasePriority/${id}`, {}, { headers: { authorization: `${token}` } });
          GetData();
        } else {
          count = 10
          clearInterval(swalCountdownInterval)
        }
      });
  };

  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(Data);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    setData(tempData);
    var order = [];
    tempData.map(function (index, element) {
      order.push({
        id: index.id,
        position: element + 1,
      });
    });
    sortCategory(order);
  };
  const sortCategory = async (Data) => {
    const results = await APIS.post('/changeApiPurchasePriorityPosition', { position: JSON.stringify(Data) }, { headers: { Authorization: `${token}` } })
    if (results.status === 200) {
      toast.success(results.response_message);
    }
  }

  useEffect(() => {
    GetData()
  }, [])
  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1">API Priority</h3>
        <div className="page-heading-btn-group">
          <Link to="/api-priority">First Try</Link>
          <Link className="active" to="/api-purchase-priority">Second Try</Link>
        </div>
        <div className="page-heading-right">
          <Link to="/api-purchase-priority/add" className="my-1 ms-3">
            {permission["API-Priority"]?.add == 1 && <Button variant="primary" value="create">Add New</Button>}
          </Link>
        </div>
      </div>
      <div className="page-content">
        <Card className="radiustop-0">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Card.Body>
              <Table className="table-bg-primary" striped bordered responsive>
                <thead>
                  <tr>
                    <th width="5%" className="text-center">No</th>
                    <th width="75%">Third Part API</th>
                    <th width="10%" className="text-center">Status</th>
                    <th width="10%" className='text-center'>Action</th>
                  </tr>
                </thead>
                <Droppable droppableId="droppable-1">
                  {(provider) => (
                    <tbody ref={provider.innerRef} {...provider.droppableProps}>
                      {
                        Data?.length > 0 ? Data?.map((val, ind) => {
                         return( <Draggable
                            key={val.id}
                            draggableId={val.id?val.id?.toString():ind.toString()}
                            index={ind}>
                            {(provider) => (
                              <tr key={ind} {...provider.draggableProps}{...provider.dragHandleProps} ref={provider.innerRef}>
                                <td className='text-center'>{ind + 1}</td>
                                <td >{val.third_party_api}</td>
                                <td className="text-center">
                                  <div className="d-flex align-items-center justify-content-center">
                                                                      <Form.Check
                                      type="switch"
                                      disabled={permission["API-Priority"]?.edit == 1 ? false : true}
                                      onChange={(e) => {
                                        Togglechange(val.id, e.target.checked, "status");
                                      }}
                                      checked={val.status === 1 ? true : false}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <div className='d-flex align-items-center justify-content-center'>
                                    <Link to={`/api-purchase-priority/view/${val.id}`} className="btn btn-icon btn-transparent btn-primary">
                                      <i className='bx bx-show'></i>
                                    </Link>
                                    <span className='border-start py-2 mx-2'></span>
                                    {permission["API-Priority"]?.delete == 1 && 
                                      <Button variant="danger" onClick={() => DeleteData(val.id)} size="sm" className="btn-icon btn-transparent">
                                        <i className='bx bx-trash' ></i>
                                      </Button>
                                    }
                                  </div>
                                </td>
                              </tr>
                            )}
                          </Draggable>)
                      }) : <tr>
                          <td colSpan="100%" className="p-0">
                            <div className='no-found'>
                              <img src="../../not-found/iptvsubcategory.svg" />
                              <p>No Found API Priority</p>
                            </div>
                          </td>
                        </tr>
                      }
                      {provider.placeholder}
                    </tbody>
                  )}
                </Droppable>
              </Table>
            </Card.Body>
          </DragDropContext>
        </Card>
      </div>
    </Layout>
  )
}

export default ApipurchasePriority