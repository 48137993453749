import React, { useEffect, useState } from "react";
import { Button, Card, Form, InputGroup, Modal, Table } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import { APIS } from "../../App";
import Layout from '../../layout/Layout';
import dayjs from "dayjs";
import Cookies from "js-cookie";
import Pagination from "rc-pagination";

var Page_array = [];
const LicenseInformation = () => {
  const token = Cookies.get("fmljwt");
  const [Data, setData] = useState([])
  const [size, setSize] = useState(10);
  const [current, setCurrent] = useState(1);
  const [PageHook, setPageHook] = useState([])
  const [loading, setIsLoading] = useState(true)
  const [query, setquery] = useState({
    search: ""
  })
  const [View_Data, setView_Data] = useState()

  const GetData = async () => {

    const Form = new FormData()
    setIsLoading(true);
    try {
      Form.append('license_no', query.search)
      Form.append('page', current)
      Form.append('limit', size)
      const result = await APIS.post("/getlicenseInformation", Form, { headers: { authorization: `${token}` } })
      setData(result.data)
      PageGetData()
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false);
    }
  }

  const paginationData = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize);
  };

  const PageGetData = async () => {
    var PageNumber = [10, 25, 50, 100]
    Page_array = []
    PageNumber.map((val, index) => {
      Page_array.push({ label: val, value: val })
    })
    setPageHook(Page_array)
  };

  useEffect(() => {
    GetData()
  }, [current, size])

  const [show, setShow] = useState(false);

  const handleShow = async (id) => {
    setShow(true)
    const Form = new FormData()
    Form.append('id', id)
    const result = await APIS.post("/getlicenseInformation", Form, { headers: { authorization: `${token}` } })
    setView_Data(result?.data?.data[0])
  }
  const handleClose = () => setShow(false);


  // const searching = async (e, name) => {
  //   if (name == "search") {
  //     setquery({ ...query, [name]: e.target.value })
  //   }
  //   setCurrent(1);
  //   setSize(10);
  // };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
        e.preventDefault(); // Prevent the default form submission behavior
        GetData()
    }
  };
  
  const handleSearch = () => {
    GetData()
  }

  const dateOptions = ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly'].map(
    item => ({ label: item, value: item })
  );

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3 className="my-1">DL Information</h3>
        <div className="page-heading-right">
          <div className="inputgroup my-1">
            <Form.Control
              type="text"
              name="reg_no"
              placeholder=" Serach License No."
              className="wv-250 ms-3"
              onChange={(e) => setquery({ ...query, ['search']: e.target.value })}
              onKeyPress={handleKeyPress}
            />
            <i className="bx bx-search" onClick={handleSearch}/>
          </div>
          <SelectPicker
            cleanable={false}
            data={dateOptions}
            searchable={false}
            defaultValue={10}
            placeholder="Select Date"
            className="wv-150 my-1 ms-3"
            placement="bottomEnd"
          />
          <SelectPicker
            cleanable={false}
            data={PageHook}
            searchable={false}
            value={size}
            className="wv-100 my-1 ms-3"
            placement="bottomEnd"
            onChange={(e) => {
              setSize(Number(e));
              setCurrent(1);
            }}
          />
        </div>
      </div>
      <div className="page-content">
        <Card className="radiustop-0">
          <Card.Body>
            {loading && <div className="loader table-loader"></div>}
            <Table className="table-bg-primary" bordered striped responsive>
              <thead>
                <tr>
                  <th width="5%" className="text-center">No</th>
                  <th width="18%">License No</th>
                  <th width="19%">Owner Name</th>
                  <th width="12%">Current Status</th>
                  <th width="12%">Date of Issue</th>
                  <th width="12%">Valid Date</th>
                  <th width="12%">Date</th>
                  <th width="10%" className="text-center">Show Details</th>
                </tr>
              </thead>
              <tbody>
                {
                  Data?.data?.length > 0 ? Data?.data?.map((val, i) => {
                    return (
                      <tr key={i}>
                        <td className='text-center'>{(current === 1) ? i + 1 : current * size + i + 1 - size}</td>
                        <td>{val.license_no}</td>
                        <td>{val.name}</td>
                        <td>{val.current_status}</td>
                        <td>{dayjs(val?.date_of_issue).format('DD-MM-YYYY')}</td>
                        <td>{dayjs(val?.to_non_transport).format('DD-MM-YYYY')}</td>
                        <td>{dayjs(val?.created_at).format('DD-MM-YYYY')}</td>
                        <td className='text-center'>
                          <Button variant="danger" className="btn btn-icon btn-transparent" onClick={() => handleShow(val.id)}>
                            <i className='bx bx-receipt'></i>
                          </Button>
                        </td>
                      </tr>
                    )
                  }) : <tr>
                    <td colSpan="100%" className="p-0">
                      <div className='no-found'>
                        <img src="../../not-found/image.svg" />
                        <p>No Found DL Information</p>
                      </div>
                    </td>
                  </tr>
                }
              </tbody>
            </Table>
            {Data?.totalcount > size ? (
              <div className="pagination-custom">
                <Pagination
                  showTitle={false}
                  className="pagination-data"
                  onChange={paginationData}
                  total={Data?.totalcount}
                  current={current}
                  pageSize={size}
                  showSizeChanger={false}
                />
              </div>
            ) : (
              ""
            )}
          </Card.Body>
        </Card>
      </div>
      <Modal
        show={show} onHide={handleClose}
        size="lg"
        aria-labelledby="user-details"
        className="user-details-modal"
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title id="user-details">
            User Details - <span className="text-primary">{View_Data?.license_no}</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="user-details">
            <p>ID</p>
            <span>{View_Data?.id}</span>
          </div>
          <div className="user-details">
            <p>Owner Name</p>
            <span>{View_Data?.name}</span>
          </div>
          <div className="user-details">
            <p>License No</p>
            <span>{View_Data?.license_no}</span>
          </div>
          <div className="user-details">
            <p>DOB</p>
            <span>{dayjs(View_Data?.dob).format('DD-MM-YYYY')}</span>
          </div>
          <div className="user-details">
            <p>Current Status</p>
            <span>{View_Data?.current_status}</span>
          </div>
          <div className="user-details">
            <p>Date Of Issue</p>
            <span>{dayjs(View_Data?.date).format('DD-MM-YYYY')}</span>
          </div>
          <div className="user-details">
            <p>Last Transaction At</p>
            <span>{View_Data?.last_transaction_at}</span>
          </div>
          <div className="user-details">
            <p>Old New DL No</p>
            <span>{View_Data?.old_new_dl_no}</span>
          </div>
          <div className="user-details">
            <p>From Non Transport</p>
            <span>{View_Data?.from_non_transport}</span>
          </div>
          <div className="user-details">
            <p>To Non Transport</p>
            <span>{dayjs(View_Data?.to_non_transport).format('DD-MM-YYYY')}</span>
          </div>
          <div className="user-details">
            <p>From Transport</p>
            <span>{dayjs(View_Data?.from_transport).format('DD-MM-YYYY')}</span>
          </div>
          <div className="user-details">
            <p>To Transport</p>
            <span>{dayjs(View_Data?.to_transport).format('DD-MM-YYYY')}</span>
          </div>
          <div className="user-details">
            <p>Hazardous Valid Till</p>
            <span>{View_Data?.hazardous_valid_till}</span>
          </div>
          <div className="user-details">
            <p>Hill Valid Till</p>
            <span>{View_Data?.hill_valid_till}</span>
          </div>
          <div className="user-details">
            <p>Cov Category</p>
            <span>{View_Data?.cov_category}</span>
          </div>
          <div className="user-details">
            <p>Class Of Vehicle</p>
            <span>{View_Data?.class_of_vehicle}</span>
          </div>
          <div className="user-details">
            <p>Cov Issue Date</p>
            <span>{View_Data?.cov_issue_date}</span>
          </div>
        </Modal.Body>
      </Modal>
    </Layout>
  )
}

export default LicenseInformation