import { createContext, useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import 'rsuite/dist/rsuite.css';
import './bootstrap.css';
import 'boxicons/css/boxicons.css';
import 'bootstrap-daterangepicker/daterangepicker.css'
import './App.css';
import './utilities.css';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { env } from './env'
import { ToastContainer } from 'react-toastify';

import Home from './pages/Home';
import Login from './pages/Login';
import PageNotFound from './pages/PageNotFound';

import RcDetails from './pages/RcDetails/RcDetails';
import RcDashboard from './pages/RcDashboard/RcDashboard';
import RcCount from './pages/RcCount/RcCount';
import RcBlock from './pages/RcBlock/RcBlock';

import CarinfoRcCount from './pages/RcPartner/CarinfoRcCount';

import LicenseInformation from './pages/LicenseInformation/LicenseInformation';
import ChallanInformation from './pages/ChallanInformation/ChallanInformation';
import ChallanInformationView from './pages/ChallanInformation/ChallanInformationView';
import Reminder from './pages/Reminder/Reminder';
import Feedback from './pages/Feedback/Feedback';
import NotificationReport from './pages/NotificationReport/NotificationReport';

import AppUpdate from './pages/AppUpdate/AppUpdate';
import AppUpdateAdd from './pages/AppUpdate/AppUpdateAdd';
import AppUpdateEdit from './pages/AppUpdate/AppUpdateEdit';
import AppUpdateView from './pages/AppUpdate/AppUpdateView';

import Authorization from './pages/Authorization/Authorization';

import ApiPriority from './pages/ApiPriority/ApiPriority';
import ApiPriorityAdd from './pages/ApiPriority/ApiPriorityAdd';
import ApiPriorityEdit from './pages/ApiPriority/ApiPriorityEdit';
import ApiPriorityView from './pages/ApiPriority/ApiPriorityView';

import Quotes from './pages/Quotes/Quotes';
import QuotesAdd from './pages/Quotes/QuotesAdd';
import QuotesView from './pages/Quotes/QuotesView';

import Module from "./pages/Module/Module"
import AddModule from "./pages/Module/ModuleAdd"
import ViewModule from "./pages/Module/ModuleView"
import EditModule from "./pages/Module/ModuleEdit"

import Role from './pages/Role/Role';
import RoleAdd from './pages/Role/RoleAdd';
import ViewRole from './pages/Role/RoleView';

import User from './pages/Permission/User';
import AddUser from './pages/Permission/UserAdd';
import ViewUser from './pages/Permission/UserView';
import EditUser from './pages/Permission/UserEdit';

import Permission from './Component/Permission';
import Cookies from 'js-cookie';
import PrivetRoutes from './Component/PrivetRoutes';
import Protected from './Component/Protected';
import ApiPurchasePriority from './pages/ApiPurchacePriority/ApiPurchasePriority';
import ApiPurchasePriorityAdd from './pages/ApiPurchacePriority/ApiPurchasePriorityAdd';
import ApiPurchasePriorityView from './pages/ApiPurchacePriority/ApiPurchasePriorityView';

import ApiClientPriority from './pages/ApiClientPriority/ApiClientPriority';
import ApiClientPriorityAdd from './pages/ApiClientPriority/ApiClientPriorityAdd';
import ApiClientPriorityEdit from './pages/ApiClientPriority/ApiClientPriorityEdit';
import ApiClientPriorityView from './pages/ApiClientPriority/ApiClientPriorityView';

import FailData from './pages/FailData/FailData';
import FailDataView from './pages/FailData/FailDataView';
import StatusData from './pages/FailData/StatusData';

import Token from './pages/Token/Token';
import RcServicesView from './pages/RcServices/RcServicesView';
import RcServicesAdd from './pages/RcServices/RcServicesAdd';
import RcServices from './pages/RcServices/RcServices';
import RcPartner from './pages/RcPartner/RcPartner';
import RcPartnerAdd from './pages/RcPartner/RcPartnerAdd';
import RcPartnerView from './pages/RcPartner/RcPartnerView';

export const API = axios.create({ baseURL: process.env.REACT_APP_BASE_URLS })
export const APIS = axios.create({ baseURL: process.env.REACT_APP_BASE_URLS })

export const AuthContext = createContext()
const App = () => {

    const [classActive, setClassActive] = useState({
        drp1: false,
        drp2: false,
        drp3: false,
    });

    const NotPermissionFunc = ({ redirectPath = "/" }) => {
        return <Navigate to={redirectPath} replace />
    }
    const token = Cookies.get('fmljwt')
    const userPermission = JSON.parse(localStorage.getItem('permissions')) ? JSON.parse(localStorage.getItem('permissions')) : []
    const roleName = localStorage.getItem('role') ? localStorage.getItem('role') : ""
    const [theme, setTheme] = useState(localStorage.getItem('theme') ? localStorage.getItem('theme') : "")
    return (
        <>
            <AuthContext.Provider value={{ classActive, setClassActive, theme, setTheme }}>
                <BrowserRouter>
                    <Routes>
                        <Route element={<Protected />}>
                            <Route path="/" element={<Login />} />
                        </Route>
                        <Route element={<PrivetRoutes />}>
                            <Route path="/Home" element={<Home />} />
                            {
                                userPermission["RC-Details"]?.view == 1 &&
                                <Route path="/rc-details" element={<RcDetails />} />
                            }
                            {
                                userPermission["RC-Details"]?.view == 1 &&
                                <Route path="/rc-dashboard" element={<RcDashboard />} />
                            }
                            {
                                userPermission["RC-Count"]?.view == 1 &&
                                <Route path="/rc-count" element={<RcCount />} />
                            }
                            {
                                userPermission["Rc-Block"]?.view == 1 &&
                                <Route path="/rc-block" element={<RcBlock />} />
                            }
                            {/* {
                                userPermission["carinfo"]?.view == 1 && */}
                            <Route path="/rc-partner/carinfo-rc-count" element={<CarinfoRcCount />} />
                            {/* } */}

                            <Route path="/rc-partner" element={<CarinfoRcCount />} />
                            <Route path="/rc-partner/add" element={<RcPartnerAdd />} />
                            <Route path="/rc-partner/edit/:id" element={<RcPartnerAdd />} />
                            <Route path="/rc-partner/view/:id" element={<RcPartnerView />} />
                            <Route path="/rc-services" element={<RcServices />} />
                            <Route path="/rc-services/add" element={<RcServicesAdd />} />
                            <Route path="/rc-services/edit/:id" element={<RcServicesAdd />} />
                            <Route path="/rc-services/view/:id" element={<RcServicesView />} />

                            {
                                userPermission["License-info"]?.view == 1 &&
                                <Route path="/license-information" element={<LicenseInformation />} />
                            }
                            {
                                userPermission["Challan-info"]?.view == 1 &&
                                <Route path="/challan-information" element={<ChallanInformation />} />
                            }
                            {
                                userPermission["Challan-info"]?.view == 1 &&
                                <Route path="/challan-information/view" element={<ChallanInformationView />} />
                            }
                            {
                                userPermission["License-info"]?.view == 1 &&
                                <Route path="/token" element={<Token />} />
                            }
                            {
                                userPermission["Reminder"]?.view == 1 &&
                                // <Route element={<Permission name="rc_reminder_view" />}>
                                <Route path="/reminder" element={<Reminder />} />
                                // </Route>
                            }
                            {
                                userPermission["Feedback-New"]?.view == 1 &&
                                <Route path="/feedback" element={<Feedback />} />
                            }
                            {
                                userPermission["Notification-Report"]?.view == 1 &&
                                <Route path="/notification-report" element={<NotificationReport />} />
                            }
                            {/* {
                                userPermission["Notification-Report"]?.view == 1 && */}
                            <Route path="/faildata" element={<FailData />} />
                            {/* } */}
                            {/* {
                                userPermission["Notification-Report"]?.view == 1 && */}
                            <Route path="/faildataview/:id" element={<FailDataView />} />
                            <Route path="/statusview/:id" element={<StatusData />} />
                            {/* } */}
                            {
                                userPermission["App-Update"]?.view == 1 &&
                                <Route path="/app-update" element={<AppUpdate />} />
                            }
                            {
                                userPermission["App-Update"]?.add == 1 &&
                                <Route path="/app-update/add" element={<AppUpdateAdd />} />

                            }
                            {
                                userPermission["App-Update"]?.edit == 1 &&
                                <Route path="/app-update/edit/:id" element={<AppUpdateEdit />} />
                            }
                            {
                                userPermission["App-Update"]?.view == 1 &&
                                <Route path="/app-update/view/:id" element={<AppUpdateView />} />
                            }
                            {
                                userPermission["API-Priority"]?.view == 1 &&
                                <Route path="/api-priority" element={<ApiPriority />} />
                            }
                            {
                                userPermission["API-Priority"]?.add == 1 &&
                                <Route path="/api-priority/add" element={<ApiPriorityAdd />} />
                            }
                            {
                                userPermission["API-Priority"]?.edit == 1 &&
                                <Route path="/api-priority/edit/:id" element={<ApiPriorityEdit />} />
                            }
                            {
                                userPermission["API-Priority"]?.view == 1 &&
                                <Route path="/api-priority/view/:id" element={<ApiPriorityView />} />
                            }

                            {/* {
                                // userPermission["API-Priority"]?.view == 1 &&
                                <Route path="/api-client-priority" element={<ApiClientPriority />} />
                            }
                            {
                                // userPermission["API-Priority"]?.add == 1 &&
                                <Route path="/api-client-priority/add" element={<ApiClientPriorityAdd />} />
                            }
                            {
                                // userPermission["API-Priority"]?.edit == 1 &&
                                <Route path="/api-client-priority/edit/:id" element={<ApiClientPriorityEdit />} />
                            }
                            {
                                // userPermission["API-Priority"]?.view == 1 &&
                                <Route path="/api-client-priority/view/:id" element={<ApiClientPriorityView />} />
                            } */}
                            {
                                userPermission["API-Priority"]?.view == 1 &&
                                <Route path="/api-purchase-priority" element={<ApiPurchasePriority />} />
                            }
                            {
                                userPermission["API-Priority"]?.add == 1 &&
                                <Route path="/api-purchase-priority/add" element={<ApiPurchasePriorityAdd />} />
                            }
                            {
                                userPermission["API-Priority"]?.edit == 1 &&
                                <Route path="/api-purchase-priority/edit/:id" element={<ApiPurchasePriorityAdd />} />
                            }
                            {
                                userPermission["API-Priority"]?.view == 1 &&
                                <Route path="/api-purchase-priority/view/:id" element={<ApiPurchasePriorityView />} />
                            }
                            {
                                userPermission["Quotes"]?.view == 1 &&
                                <Route path="/quotes" element={<Quotes />} />
                            }
                            {
                                userPermission["Quotes"]?.add == 1 &&
                                <Route path="/quotes/add" element={<QuotesAdd />} />
                            }
                            {
                                userPermission["Quotes"]?.edit == 1 &&
                                <Route path="/quotes/edit/:id" element={<QuotesAdd />} />
                            }
                            {
                                userPermission["Quotes"]?.view == 1 &&
                                <Route path="/quotes/view/:id" element={<QuotesView />} />
                            }
                            {
                                userPermission["Authorization"]?.view == 1 &&
                                <Route path="/authorization" element={<Authorization />} />
                            }


                            {roleName == "ADMIN" && <>

                                <Route path="/module" element={<Module />} />
                                <Route path="/module/add" element={<AddModule />} />
                                <Route path="/module/view/:id" element={<ViewModule />} />
                                <Route path="/module/edit/:id" element={<EditModule />} />

                                <Route path="/role" element={<Role />} />
                                <Route path="/role/add" element={<RoleAdd />} />
                                <Route path="/role/edit/:id" element={<RoleAdd />} />
                                <Route path="/role/view/:id" element={<ViewRole />} />

                                <Route path="/user" element={<User />} />
                                <Route path="/user/add" element={<AddUser />} />
                                <Route path="/user/view/:id" element={<ViewUser />} />
                                <Route path="/user/edit/:id" element={<EditUser />} /></>}
                        </Route>
                        <Route path="*" element={<PageNotFound />} />
                    </Routes>
                </BrowserRouter>
                <ToastContainer position='bottom-right' autoClose={500} />
            </AuthContext.Provider>
        </>
    )
}

export default App;
