import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from 'react-router-dom';
import Layout from '../../layout/Layout';
import { APIS } from '../../App';
import Swal from 'sweetalert2';
import $ from 'jquery';
import Cookies from 'js-cookie';


const QuotesView = () => {
  const permission = JSON.parse(localStorage.getItem('permissions'))

  const token = Cookies.get("fmljwt");
  const params = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState();

  const getView = async () => {
    const result = await APIS.post(`/getSinglequotes/${params.id}`, {}, { headers: { authorization: `${token}` } });
    setData(result?.data?.data[0]);
  }

  let count = 10
  let swalCountdownInterval
  const handleDelete = async () => {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-danger btn-lg counter",
        cancelButton: "btn btn-primary btn-lg me-3",
      },
      buttonsStyling: false,
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure you want to delete?",
        text: "You won't be able to revert this!",
        imageUrl: '../../icon/alert.svg',
        imageWidth: 80,
        imageHeight: 80,
        confirmButtonText: "OK (10)",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        reverseButtons: true,
        didOpen: () => {
          $(".swal2-confirm").attr('disabled', true);
          swalCountdownInterval = setInterval(function () {
            count--
            if (count < 1) {
              $(".counter").text(`OK`)
              $(".swal2-confirm").attr('disabled', false);
              clearInterval(swalCountdownInterval)
            } else {
              $(".counter").text(`OK (${count})`)
            }
          }, 1000);
        }
      })
      .then(async (result) => {
        if (result.isConfirmed) {
          const deletedata = await APIS.post(`/deleteQuotesDetail/${params.id}`, {}, { headers: { authorization: `${token}` } })
          navigate(`/quotes`)
        } else {
          count = 10
          clearInterval(swalCountdownInterval)
        }
      });
  };

  useEffect(() => {
    getView(params);
  }, [params]);

  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3><Link to="/quotes" className='btn btn-primary btn-icon-lg me-3'><i className='bx bxs-left-arrow-alt'></i></Link>Quotes View</h3>
        <div className="page-heading-right">
          {permission["Quotes"]?.edit == 1 && <Link to={`/quotes/edit/${params.id}`}>
            <Button variant="primary ms-3 my-1" value="edit">Edit</Button>
          </Link>}
          {permission["Quotes"]?.delete == 1 && <Button variant="danger ms-3 my-1 btn-icon-lg" type="button" onClick={handleDelete}><i className="bx bx-trash-alt"></i></Button>
          }        </div>
      </div>
      <div className='page-content'>
        <Card>
          <Card.Body>
            <Row>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>English Quotes</p><span>{data?.en}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Hindi Quotes</p><span>{data?.hi}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Marathi Quotes</p><span>{data?.mr}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Gujarati Quotes</p><span>{data?.gu}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Tamil Quotes</p><span>{data?.ta}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Telugu Quotes</p><span>{data?.te}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Kannada Quotes</p><span>{data?.kn}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Bengali Quotes</p><span>{data?.bn}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Panjab Quotes</p><span>{data?.pa}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Odisha Quotes</p><span>{data?.or}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Malayalam Quotes</p><span>{data?.ml}</span>
                </div>
              </Col>
              <Col md={4}>
                <div className='mb-4'>
                  <p className='mb-0 fw-bold'>Status</p><span>{data?.status == 1 ? "On" : "Off"}</span>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </Layout>
  )
}

export default QuotesView