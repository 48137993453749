import React, { useState } from "react";
import { Card } from 'react-bootstrap';
import ApexCharts from 'react-apexcharts'
import { SelectPicker } from 'rsuite';

const data = ['Eugenia', 'Bryan', 'Linda', 'Nancy', 'Lloyd', 'Alice', 'Julia', 'Albert'].map(
    item => ({ label: item, value: item })
);

const VehicleType = () => {
    
    const bike = "#FF4560"
    const car = "#775DD0"
    const bus = "#008FFB"
    const ecar = "#00D8B6"
    const truck = "#FEB019"

    const [chartVehicleType, setChartVehicleType] = useState({
        series: [10, 12, 24, 36, 42],
        options: {
            chart: {
                type: 'pie',
            },
            labels: ['BIKE', 'CAR', 'BUS', 'E-CAR', 'TRUCK'],
            colors: [bike, car, bus, ecar, truck],
            legend: {
                show: true,
                position: 'bottom',
                fontFamily: 'Maven Pro',
                fontSize: '13px',
                fontWeight: 500,
                labels: {
                    colors: "#6C737D",
                },
                markers: {
                    width: 0,
                    height: 0,
                },
                customLegendItems: [
                    '<img src="../dashboard/bike.svg" alt="Bike" /> BIKE',
                    '<img src="../dashboard/car.svg" alt="Car" /> CAR',
                    '<img src="../dashboard/bus.svg" alt="Bus" /> BUS',
                    '<img src="../dashboard/ecar.svg" alt="E-Car" /> BUS',
                    '<img src="../dashboard/truck.svg" alt="Truck" /> BUS',
                ],
                onItemClick: {
                    toggleDataSeries: false
                },
                onItemHover: {
                    highlightDataSeries: false
                },
            },
            states: {
                hover: {
                    filter: {
                        type: 'none',
                        value: 0,
                    }
                },
                active: {
                    filter: {
                        type: 'none',
                        value: 0,
                    }
                },
            },
            tooltip: {
                enabled: true,
                shared: true,
                intersect: false,
                custom: function({ series, seriesIndex, dataPointIndex, w }) {
                    const seriesName = w.globals.labels[seriesIndex];
                    const color = w.config.colors[seriesIndex];
                    return `
                        <div class="tooltipbox">
                            <h6>Vehicle Type</h6>
                            <div class="tooltipbody">
                                <p>
                                    <span class="legend" style="background-color:${color}"></span>${seriesName} : <span class="tooltiptext">&nbsp;${series&&series[seriesIndex]}</span>
                                </p>
                            </div>
                        </div>
                    `;
                }
            },
            dataLabels: {
                enable: true,
                textAnchor: 'middle',
                style: {
                    fontFamily: 'Maven Pro',
                    fontSize: '14px',
                    fontWeight: '500',
                },
                dropShadow: {
                    enabled: false,
                },
                formatter: function (val, opts) {
                    return opts.w.config.series[opts.seriesIndex]+"T"
                },
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        decimalsInFloat: 0,
                        offset: -10,
                    }
                }
            }
        }
    });
    return(
        <Card>
            <Card.Header className='pb-0'>
                <div className="chart-title">
                    <div>
                        <h4>Vehicle Type</h4>
                        <p>Total Vehicle : 1,20,000</p>
                    </div>
                    <div className='chartfilter'>
                        <SelectPicker
                            cleanable={false}
                            data={data}
                            size="md"
                            searchable={false}
                            defaultValue={""}
                            placeholder="ALL STATE"
                            placement="bottomEnd"
                            className="wv-175 ms-3"
                        />
                    </div>
                </div>
            </Card.Header>
            <Card.Body className="d-flex justify-content-center">
                <ApexCharts options={chartVehicleType.options} series={chartVehicleType.series} type="pie" width={450} />
            </Card.Body>
        </Card>
    )
}
export default VehicleType