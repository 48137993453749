import React from "react";

const PageNotFound=()=>{
    return(
    <>
        <img src="../../not-found/graphreport.svg" style={{width:"50%",height:"50vh",marginTop:"100px",marginLeft:"500px"}} alt="" />
        <h1 style={{marginLeft:"860px",marginTop:"50px"}}>Page Not Found</h1>
        </>
    )
}

export default PageNotFound;