import React, { useState } from "react";
import { Card } from 'react-bootstrap';
import ApexCharts from 'react-apexcharts'
import { SelectPicker } from 'rsuite';

const data = ['Eugenia', 'Bryan', 'Linda', 'Nancy', 'Lloyd', 'Alice', 'Julia', 'Albert'].map(
    item => ({ label: item, value: item })
);

const VehicleAgeType = () => {

    const Y10 = "#FEB019"
    const Y15 = "#FF4560"
    const Y20 = "#775DD0"
    const Y25 = "#008FFB"
    const Y30 = "#00D8B6"

    const [chartVehicleAgeType, setChartVehicleAgeType] = useState({
        series: [25, 12, 13, 25, 25],
        options: {
            chart: {
                type: 'donut',
            },
            labels: ['5-10Y', '10-15Y', '15-20Y', '20-25Y', '25-30Y'],
            colors: [ Y10, Y15, Y20, Y25, Y30],
            legend: {
                show: true,
                position: 'bottom',
                fontFamily: 'Maven Pro',
                fontSize: '13px',
                fontWeight: 500,
                labels: {
                    colors: "#6C737D",
                },
                markers: {
                    width: 10,
                    height: 10,
                    offsetY: 1,
                },
                onItemClick: {
                    toggleDataSeries: false
                },
                onItemHover: {
                    highlightDataSeries: false
                },
            },
            states: {
                hover: {
                    filter: {
                        type: 'none',
                        value: 0,
                    }
                },
                active: {
                    filter: {
                        type: 'none',
                        value: 0,
                    }
                },
            },
            tooltip: {
                enabled: true,
                shared: true,
                intersect: false,
                custom: function({ series, seriesIndex, dataPointIndex, w }) {
                    const seriesName = w.globals.labels[seriesIndex];
                    const color = w.config.colors[seriesIndex];
                    return `
                        <div class="tooltipbox">
                            <h6>Vehicle Age Type</h6>
                            <div class="tooltipbody">
                                <p>
                                    <span class="legend" style="background-color:${color}"></span>${seriesName} : <span class="tooltiptext">&nbsp;${series&&series[seriesIndex]}</span>
                                </p>
                            </div>
                        </div>
                    `;
                }
            },
            dataLabels: {
                enable: true,
                textAnchor: 'middle',
                style: {
                    fontFamily: 'Maven Pro',
                    fontSize: '14px',
                    fontWeight: '500',
                },
                dropShadow: {
                    enabled: false,
                },
                formatter: function (val) {
                    return val + "%"
                },
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        decimalsInFloat: 0,
                        offset: 0,
                    }
                }
            }
        }
    });
    return(
        <Card className='h-100'>
            <Card.Header className='pb-0'>
                <div className="chart-title">
                    <div>
                        <h4>Vehicle Age Type</h4>
                    </div>
                    <div className='chartfilter'>
                        <SelectPicker
                            cleanable={false}
                            data={data}
                            size="md"
                            searchable={false}
                            defaultValue={""}
                            placeholder="SELECT STATE"
                            placement="bottomEnd"
                            className="wv-175 ms-3"
                        />
                    </div>
                </div>
            </Card.Header>
            <Card.Body className="d-flex justify-content-center">
                <ApexCharts options={chartVehicleAgeType.options} series={chartVehicleAgeType.series} type="donut" width={450} />
            </Card.Body>
        </Card>
    )
}

export default VehicleAgeType