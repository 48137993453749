import React, { useEffect, useState } from 'react'
import Layout from '../../layout/Layout'
import { SelectPicker } from 'rsuite'
import { Button, Card, Form, InputGroup, Table } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'
import Pagination from 'rc-pagination'
import Cookies from 'js-cookie'
import axios from 'axios'
import moment from 'moment-timezone';
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { APIS } from '../../App'

const status = [
    { value: 0, label: "Pending/Fail" },
    { value: 1, label: "App Success" },
    { value: 3, label: "First Priority Source Success" },
    { value: 2, label: "Second Priority Source Success" },
    { value: 4, label: "Script Success" },

]

const FailDataView = () => {
    const token = Cookies.get("fmljwt");
    const [size, setSize] = useState(100);
    const [loading, setIsLoading] = useState(false)
    const [current, setCurrent] = useState(1);
    const [allFailDataList, setAllFailDataList] = useState([])
    const [stateData, setStateData] = useState([])
    const [selectedStateCode, setSelectedStateCode] = useState("ALL")
    const [search, setSearch] = useState("")
    const [inputNumber, setInputNumber] = useState("")
    const [selectedDeviceType, setSelectedDeviceType] = useState("All");
    const [selectedStatus, setSelectedStatus] = useState(0);
    const params = useParams()
    const paginationData = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize);
    };

    const PageSize = [100, 500, 1000].map((val, index) => ({ label: val, value: val }));
    const deviceType = ["All", "ios", "android"].map((val, index) => ({ label: val, value: val }));
    useEffect(() => {
        getStateData()
    }, [])
    useEffect(() => {
        getFailDataList()
    }, [current, selectedStateCode, size, search, selectedDeviceType, selectedStatus])

    const getStateData = async () => {
        var state = ['ALL', 'AN', 'AP', 'AR', 'AS', 'BR', 'CH', 'CG', 'DD', 'DL', 'GA', 'GJ', 'HR', 'HP', 'JK', 'JH', 'KA', 'KL', 'LA', 'LD', 'MP', 'MH', 'MN', 'ML', 'MZ', 'NL', 'OD', 'PY', 'PB', 'RJ', 'SK', 'TN', 'TS', 'TR', 'UP', 'UK', 'WB']
        let State_array = []
        state?.map((val, index) => {
            State_array.push({ label: val, value: val })
        })
        setStateData(State_array)
    };

    const getFailDataList = async () => {
        setIsLoading(true);
        const Form = new FormData()
        Form.append("state", selectedStateCode)
        if (params?.id?.includes(" - ")) {
            Form.append("startdate", params.id?.split(" - ")[0])
            Form.append("enddate", params.id?.split(" - ")[1])
        } else {
            Form.append("startdate", params.id)
            Form.append("enddate", params.id)
        }
        selectedDeviceType != "All" && Form.append("deviceType", selectedDeviceType)
        Form.append("status", selectedStatus)
        Form.append("searchTerm", search)
        Form.append("page", current)
        Form.append("pageSize", size)
        try {
            const result = await APIS.post("/view_fail_data", Form, { headers: { authorization: `${token}` } })
            setAllFailDataList(result?.data)
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    const convertFailReason = (fail_reason) => {
        if (fail_reason != "") {
            let result = fail_reason?.trim()?.split(',')
            return <ul className='mb-0'>{result.map((value, index) => (
                value &&
                <li key={index}>
                    {value}
                </li>
            ))}</ul>
        } else {
            return "-"
        }
    }

    const [dateRanges, setdateRanges] = useState({
        start: moment().subtract(29, 'days'),
        end: moment(),
    });
    const { start, end } = dateRanges;

    return (
        <Layout sidebar={true}>
            <div className="page-heading backbtn">
                <h3>
                    <Link to="/faildata" className='btn btn-transparent btn-icon me-2'>
                        <i className='bx bx-chevron-left' ></i>
                    </Link>
                    RC Fail Data <span> ({allFailDataList?.totalCountRecord})</span>
                </h3>
                <h3>{params?.id?.includes(" - ") ? params?.id : moment(params.id).format("DD-MM-YYYY")}</h3>
                <div className="page-heading-right flex-nowrap">
                    <div className="inputgroup my-1">
                        <Form.Control
                            type="text"
                            name="searchvalue"
                            placeholder="Search fail data"
                            className="wv-225"
                            value={inputNumber}
                            onChange={(e) => { e.target?.value == "" && setSearch(""); setInputNumber(e.target.value) }}
                        />
                        <i className="bx bx-search" onClick={() => { setSearch(inputNumber); setCurrent(1); }} />
                    </div>
                    <SelectPicker
                        cleanable={false}
                        data={status}
                        searchable={false}
                        onChange={(e) => setSelectedStatus(e)}
                        value={selectedStatus}
                        placement="bottomEnd"
                        placeholder="Select DeviceType"
                        className="wv-225 my-1 ms-3"
                    />
                    <SelectPicker
                        cleanable={false}
                        data={deviceType}
                        searchable={false}
                        onChange={(e) => setSelectedDeviceType(e)}
                        value={selectedDeviceType}
                        placement="bottomEnd"
                        placeholder="Select DeviceType"
                        className="wv-150 my-1 ms-3"
                    />
                    <SelectPicker
                        data={stateData}
                        cleanable={false}
                        value={selectedStateCode}
                        // searchable={false}
                        onChange={(e) => { setSelectedStateCode(e); setSearch(""); setCurrent(1); }}
                        defaultValue={""}
                        className="wv-100 my-1 ms-3"
                        placement="bottomEnd"
                    />
                    <SelectPicker
                        cleanable={false}
                        data={PageSize}
                        searchable={false}
                        value={size}
                        placement="bottomEnd"
                        className="wv-100 my-1 ms-3"
                        onChange={(e) => {
                            setSize(Number(e));
                            setCurrent(1);
                        }}
                    />
                </div>
            </div>
            <div className="page-content">
                <Card className="radiustop-0">
                    <Card.Body>
                        {loading && <div className="loader table-loader"></div>}
                        <Table className="table-bg-primary" striped bordered responsive>
                            <thead>
                                <tr>
                                    <th width="5%" className="text-center">No</th>
                                    <th width="20%" className='text-center'>Vehicle No</th>
                                    <th width="10%" className='text-center'>Time</th>
                                    <th width="10%" className='text-center'>Device Type</th>
                                    <th width="55%">Fail Reason</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allFailDataList?.data?.length > 0 ? allFailDataList?.data?.map((item, index) => {
                                    const formattedTime = moment(item?.created_at)
                                        .tz('Asia/Kolkata')
                                        .format("hh:mm A");
                                    return (<tr key={index}>
                                        <td className='text-center'>{(current === 1) ? index + 1 : current * size + index + 1 - size}</td>
                                        <td className='text-center'>{item?.reg_no}</td>
                                        <td className='text-center'>{formattedTime}</td>
                                        <td className='text-center'>{item?.device_type ? item?.device_type : "-"}</td>
                                        <td>{item?.fail_reason ? convertFailReason(item?.fail_reason) : "-"}</td>
                                    </tr>)
                                })
                                    :
                                    <tr>
                                        <td colSpan="100%" className="p-0">
                                            <div className='no-found'>
                                                <img src="../../not-found/image.svg" />
                                                <p>No Found RC Fail Data</p>
                                            </div>
                                        </td>
                                    </tr>}
                            </tbody>
                        </Table>
                        {allFailDataList?.totalCountRecord > size && <div className="pagination-custom">
                            <Pagination
                                showTitle={false}
                                className="pagination-data"
                                onChange={paginationData}
                                total={allFailDataList?.totalCountRecord}
                                current={current}
                                pageSize={size}
                                showSizeChanger={false}
                            />
                        </div>}
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default FailDataView