import React, { useEffect, useState } from "react";
import { Card, Col, Form, Row } from 'react-bootstrap';
import { toast } from "react-toastify";
import { API, APIS } from "../../App";
import Switch from 'react-switch';
import Cookies from "js-cookie";
import Layout from '../../layout/Layout';

const Authorization = () => {
    const token = Cookies.get("fmljwt");
    const [Data, setData] = useState({
        id: "",
        android: 0,
        ios: 0,
        android_token: 0,
        android_app_version: 0,
        android_package_name: 0,
        ios_token: 0,
        ios_app_version: 0,
        ios_package_name: 0,
        parivahan_api: 0,
        redirect_website: 0,
        otp_verify_android: 0,
        hard_otp_verify_android: 0,
        otp_verify_ios: 0,
        hard_otp_verify_ios: 0,
        parivahan_dl: 0,
        cuvora_parivahan: 0,
    })

    const GetView = async () => {
        try {
            
            const response = await APIS.get('/get_proxy_status', {}, { headers: { authorization: `${token}` } })
            setData(response.data.data)
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        GetView()
    }, [])

    const ToggleChange = async (e, name) => {
        setData({
            ...Data,
            [name]: e === true ? 1 : 0,
        });
      
        const Form = new FormData();
        Form.append("name", name);
        Form.append("id", Data.id);
        Form.append("status", e === true ? 1 : 0);

        try {
            const result = await API.post("/update_proxy_status", Form, { headers: { authorization: `${token}` } });
            if (result) {
                toast.success("Status Update successfully");
            }
            
        } catch (error) {
            console.log('error', error)
        }
    }


    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Authorization</h3>
            </div>

            <div className="page-content">
                <Form noValidate >
                    <Card className="mb-4 ">
                        <Card.Body>
                            <h4 className="mb-4 fw-700">Android</h4>
                            <Row>
                                <Col md={2}>
                                    <Form.Label htmlFor="status" className="d-block mb-2">
                                        Android
                                    </Form.Label>
                               
                                    <Form.Check
                                      type="switch"
                                      onChange={(e) => ToggleChange(e.target.checked, "android")}
                                      name="android"
                                      checked={Data.android === 1 ? true : false}
                                    />
                                </Col>

                                <Col md={2}>
                                    <Form.Label htmlFor="status" className="d-block mb-2">
                                        Android Token
                                    </Form.Label>
                                
                                    <Form.Check
                                      type="switch"
                                      onChange={(e) => ToggleChange(e.target.checked, "android_token")}
                                      name="android_token"
                                      checked={Data.android_token === 1 ? true : false}
                                    />
                                </Col>

                                <Col md={2}>
                                    <Form.Label htmlFor="status" className="d-block mb-2">
                                        Android App Version
                                    </Form.Label>
                                    
                                    <Form.Check
                                      type="switch"
                                      onChange={(e) => ToggleChange(e.target.checked, "android_app_version")}
                                      name="android_app_version"
                                      checked={Data.android_app_version === 1 ? true : false}
                                    />
                                </Col>

                                <Col md={2}>
                                    <Form.Label htmlFor="status" className="d-block mb-2">
                                        Android Package Name
                                    </Form.Label>
                                  
                                    <Form.Check
                                      type="switch"
                                      onChange={(e) => ToggleChange(e.target.checked, "android_package_name")}
                                        name="android_package_name"
                                        checked={Data.android_package_name === 1 ? true : false}
                                    />
                                </Col>

                                <Col md={2}>
                                    <Form.Label htmlFor="status" className="d-block mb-2">
                                        OTP Verify Android
                                    </Form.Label>
                                   
                                    <Form.Check
                                      type="switch"
                                      onChange={(e) => ToggleChange(e.target.checked, "otp_verify_android")}
                                      name="otp_verify_android"
                                      checked={Data.otp_verify_android === 1 ? true : false}
                                    />
                                </Col>

                                <Col md={2}>
                                    <Form.Label htmlFor="status" className="d-block mb-2">
                                        Hard OTP Verify Android
                                    </Form.Label>
                                    
                                    <Form.Check
                                      type="switch"
                                      onChange={(e) => ToggleChange(e.target.checked, "hard_otp_verify_android")}
                                      name="hard_otp_verify_android"
                                      checked={Data.hard_otp_verify_android === 1 ? true : false}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    {/* IOS */}

                    <Card className="mb-4 ">
                        <Card.Body>
                            <h4 className="fw-700 mb-4">iOS</h4>
                            <Row>
                                <Col md={2}>
                                    <Form.Label htmlFor="status" className="d-block mb-2">
                                        iOS
                                    </Form.Label>
                                
                                    <Form.Check
                                      type="switch"
                                      onChange={(e) => ToggleChange(e.target.checked, "ios")}
                                      name="ios"
                                      checked={Data.ios === 1 ? true : false}
                                    />
                                </Col>

                                <Col md={2}>
                                    <Form.Label htmlFor="status" className="d-block mb-2">
                                        iOS Token
                                    </Form.Label>
                                    
                                    <Form.Check
                                      type="switch"
                                      onChange={(e) => ToggleChange(e.target.checked, "ios_token")}
                                      name="ios_token"
                                      checked={Data.ios_token === 1 ? true : false}
                                    />
                                </Col>

                                <Col md={2}>
                                    <Form.Label htmlFor="status" className="d-block mb-2">
                                        iOS App Version
                                    </Form.Label>
                                    
                                    <Form.Check
                                      type="switch"
                                      onChange={(e) => ToggleChange(e.target.checked, "ios_app_version")}
                                        name="ios_app_version"
                                        checked={Data.ios_app_version === 1 ? true : false}
                                    />
                                </Col>

                                <Col md={2}>
                                    <Form.Label htmlFor="status" className="d-block mb-2">
                                        iOS Package Name
                                    </Form.Label>
                                    
                                    <Form.Check
                                      type="switch"
                                      onChange={(e) => ToggleChange(e.target.checked, "ios_package_name")}
                                      name="ios_package_name"
                                      checked={Data.ios_package_name === 1 ? true : false}
                                    />
                                </Col>

                                <Col md={2}>
                                    <Form.Label htmlFor="status" className="d-block mb-2">
                                        OTP Verify iOS
                                    </Form.Label>
                                    
                                    <Form.Check
                                      type="switch"
                                      onChange={(e) => ToggleChange(e.target.checked, "otp_verify_ios")}
                                      name="otp_verify_ios"
                                      checked={Data.otp_verify_ios === 1 ? true : false}
                                    />
                                </Col>

                                <Col md={2}>
                                    <Form.Label htmlFor="status" className="d-block mb-2">
                                        Hard OTP Verify iOS
                                    </Form.Label>
                                   
                                    <Form.Check
                                      type="switch"
                                      onChange={(e) => ToggleChange(e.target.checked, "hard_otp_verify_ios")}
                                      name="hard_otp_verify_ios"
                                      checked={Data.hard_otp_verify_ios === 1 ? true : false}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>

                    {/* Parivahan */}
                    <Card className="mb-4 ">
                        <Card.Body>
                            <h4 className="mb-4 fw-700">Parivahan API Status</h4>
                            <Row>
                                <Col md={2}>
                                    <Form.Label htmlFor="status" className="d-block mb-2">
                                        Parivahan API
                                    </Form.Label>
                                  
                                    <Form.Check
                                      type="switch"
                                      onChange={(e) => ToggleChange(e.target.checked, "parivahan_api")}
                                      name="parivahan_api"
                                      checked={Data.parivahan_api === 1 ? true : false}
                                    />
                                </Col>

                                <Col md={2}>
                                    <Form.Label htmlFor="status" className="d-block mb-2">
                                        Website Redirect
                                    </Form.Label>
                                    
                                    <Form.Check
                                      type="switch"
                                      onChange={(e) => ToggleChange(e.target.checked, "redirect_website")}
                                      name="redirect_website"
                                      checked={Data.redirect_website === 1 ? true : false}
                                    />
                                </Col>

                                <Col md={2}>
                                    <Form.Label htmlFor="status" className="d-block mb-2">
                                        Cuvora Parivahan
                                    </Form.Label>
                                  
                                    <Form.Check
                                      type="switch"
                                      onChange={(e) => ToggleChange(e.target.checked, "cuvora_parivahan")}
                                      name="cuvora_parivahan"
                                      checked={Data.cuvora_parivahan === 1 ? true : false}
                                    />
                                </Col>

                                <Col md={2}>
                                    <Form.Label htmlFor="status" className="d-block mb-2">
                                        Parivahan DL
                                    </Form.Label>
                                    
                                    <Form.Check
                                      type="switch"
                                      onChange={(e) => ToggleChange(e.target.checked, "parivahan_dl")}
                                      name="parivahan_dl"
                                      checked={Data.parivahan_dl === 1 ? true : false}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Form>
            </div>
        </Layout>
    )
}

export default Authorization