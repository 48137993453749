import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import { APIS } from "../../App";
import Layout from '../../layout/Layout';
import dayjs from "dayjs";
import Cookies from "js-cookie";
import Pagination from "rc-pagination";

var Page_array = [];
var status_array = [];
const NotificationReport = () => {
    const token = Cookies.get("fmljwt");
    const [Data, setData] = useState([])
    const [size, setSize] = useState(10);
    const [current, setCurrent] = useState(1);
    const [PageHook, setPageHook] = useState([])
    const [loading, setIsLoading] = useState(true)
    const [StatusData, setStatusData] = useState([])
    const [Query, setQuery] = useState({
        status: "",
    });

    const GetData = async () => {
        setIsLoading(true);
        try {
            const Form = new FormData()
            Form.append("type", Query.status)
            Form.append("page", current)
            Form.append("limit", size)
            const result = await APIS.post("/getNotificationReportData", Form, { headers: { authorization: `${token}` } })
            setData(result.data)
            PageGetData()
            StatusDropdwon()
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    const StatusDropdwon = async () => {
        var StatusData = ["Reminder", "Fail_Data"]
        status_array = []
        status_array.push({ label: "Select Type", value: "" })
        StatusData.map((val, index) => {
            status_array.push({ label: val, value: val })
        })
        setStatusData(status_array)
    };


    // Paggintion Code //

    const paginationData = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize);
    };

    const PageGetData = async () => {
        var PageNumber = [10, 25, 50, 100]
        Page_array = []
        PageNumber.map((val, index) => {
            Page_array.push({ label: val, value: val })
        })
        setPageHook(Page_array)
    };

    const QueryHendler = async (e, name) => {

        setQuery({ ...Query, [name]: e })

        setCurrent(1)
    }


    useEffect(() => {
        GetData()
    }, [current, size, Query])
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">Notification Report</h3>
                <div className="page-heading-right">
                    <SelectPicker
                        cleanable={false}
                        data={StatusData}
                        searchable={false}
                        defaultValue={""}
                        className="wv-200 my-1 ms-3"
                        onChange={(e) => QueryHendler(e, "status")}
                    />
                    <SelectPicker
                        cleanable={false}
                        data={PageHook}
                        searchable={false}
                        value={size}
                        className="wv-100 my-1 ms-3"
                        onChange={(e) => {
                            setSize(Number(e));
                            setCurrent(1);
                        }}
                    />
                </div>
            </div>
            <div className="page-content">
                <Card className="radiustop-0">
                    <Card.Body>
                        {loading && <div className="loader table-loader"></div>}
                        <Table className="table-bg-primary" striped bordered responsive>
                            <thead>
                                <tr>
                                    <th width="5%" className="text-center">No</th>
                                    <th width="35%">Type</th>
                                    <th width="15%">Date</th>
                                    <th width="15%">Total Records</th>
                                    <th width="15%">Success Data</th>
                                    <th width="15%">Fail Data</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Data?.data?.length > 0 ? Data?.data?.map((val, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className='text-center'>{(current === 1) ? i + 1 : current * size + i + 1 - size}</td>
                                                <td>{val.type}</td>
                                                <td>{val.date}</td>
                                                {/* <td>{dayjs(val.date).format("DD-MM-YYYY")}</td> */}
                                                <td>{val.total}</td>
                                                <td>{val.success}</td>
                                                <td>{val.fail}</td>
                                            </tr>
                                        )
                                    }) : <tr>
                                        <td colSpan="100%" className="p-0">
                                            <div className='no-found'>
                                                <img src="../../not-found/image.svg" />
                                                <p>No Found Notification Report</p>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                        {Data.totalcount > size ? (
                            <div className="pagination-custom">
                                <Pagination
                                    showTitle={false}
                                    className="pagination-data"
                                    onChange={paginationData}
                                    total={Data.totalcount}
                                    current={current}
                                    pageSize={size}
                                    showSizeChanger={false}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default NotificationReport