import Cookies from 'js-cookie'
import { APIS } from '../../App';


const token = Cookies.get("fmljwt");

export const getServices = async (payload) => {
      let result
      try {
            result = await APIS.post("/get_own_service_data", payload, { headers: { authorization: `${token}` } })

      } catch (error) {
            console.log(error)
      }
      return result?.data
}
export const serviceUpdate = async (payload) => {
      let result
      try {
            result = await APIS.post("/store_own_service_data", payload, { headers: { authorization: `${token}` } })

      } catch (error) {
            console.log(error)
      }
      return result?.data
}
export const deleteService = async (payload) => {
      let result
      try {
            result = await APIS.post("/delete_own_service_data", payload, { headers: { authorization: `${token}` } })

      } catch (error) {
            console.log(error)
      }
      return result?.data
}
