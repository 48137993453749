import React, { useEffect, useState } from "react";
import { Button, Card, Form, Table } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import { APIS } from "../../App";
import Layout from '../../layout/Layout';
import Cookies from "js-cookie";
import Pagination from "rc-pagination";

var Page_array = [];
var State_array = [];
var Reminder_type_array = [];
var Doc_type_array = [];
const RcReminder = () => {
    const token = Cookies.get("fmljwt");
    const [Data, setData] = useState([])
    const [size, setSize] = useState(10);
    const [current, setCurrent] = useState(1);
    const [PageHook, setPageHook] = useState([])
    const [loading, setIsLoading] = useState(true)
    const [State_Data, setState_Data] = useState([])
    const [Remider_type_Data, setRemider_type_Data] = useState([])
    const [Doc_type_Data, setDoc_type_Data] = useState([])
    const [Query, setQuery] = useState({
        state: "",
        searchvalue: "",
        reminder_type: "",
        doc_type: "",
    });

    const GetData = async () => {
        setIsLoading(true);
        try {
            const Form = new FormData()
            Form.append('page', current)
            Form.append('limit', size)
            Form.append("searchData", Query.searchvalue);
            Form.append("state", Query.state)
            Form.append("reminder_type", Query.reminder_type)
            Form.append("document_type", Query.doc_type)
            const result = await APIS.post("/getReminder", Form, { headers: { authorization: `${token}` } })
            setData(result.data)
            PageGetData()
            stateGetData()
            Reminder_type_GetData()
            Doc_type_GetData()
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    const stateGetData = async () => {
        var state = ['AN', 'AP', 'AR', 'AS', 'BR', 'CH', 'CG', 'DD', 'DL', 'GA', 'GJ', 'HR', 'HP', 'JK', 'JH', 'KA', 'KL', 'LA', 'LD', 'MP', 'MH', 'MN', 'ML', 'MZ', 'NL', 'OD', 'PY', 'PB', 'RJ', 'SK', 'TN', 'TS', 'TR', 'UP', 'UK', 'WB']
        State_array = []
        State_array.push({ label: "All", value: "" })
        state.map((val, index) => {
            State_array.push({ label: val, value: val })
        })
        setState_Data(State_array)
    };

    const Reminder_type_GetData = async () => {
        var ReminderData = [
            { lable: "Before a Month", value: "1" },
            { lable: "Before a Week", value: "2" },
            { lable: "Before 1 Day", value: "3" },
            { lable: "Same Day", value: "4" },
        ]
        Reminder_type_array = []
        Reminder_type_array.push({ label: "Select Reminder Type", value: "" })
        ReminderData.map((val, index) => {
            Reminder_type_array.push({ label: val.lable, value: val.value })
        })
        setRemider_type_Data(Reminder_type_array)
    };

    const Doc_type_GetData = async () => {
        var DocumentData = [
            { lable: "Driving lincese", value: 1 },
            { lable: "Insurance", value: 2 },
            { lable: "PUC", value: 3 },
            { lable: "Registration Certificate", value: 4 },
            { lable: "Service Log", value: 5 },
            { lable: "Other Document", value: 6 },
            { lable: "Fitness Upto", value: 7 },
        ]
        Doc_type_array = []
        Doc_type_array.push({ label: "Select Document Type", value: "" })
        DocumentData.map((val, index) => {
            Doc_type_array.push({ label: val.lable, value: val.value })
        })
        setDoc_type_Data(Doc_type_array)
    };

    // Paggintion Code //

    const paginationData = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize);
    };

    const PageGetData = async () => {
        var PageNumber = [10, 25, 50, 100]
        Page_array = []
        PageNumber.map((val, index) => {
            Page_array.push({ label: val, value: val })
        })
        setPageHook(Page_array)
    };

    const QueryHendler = async (e, name) => {
        if (name === "searchvalue") {
            setQuery({ ...Query, [e.target.name]: e.target.value });
        } else if (name === "") {
            GetData()
        } else {
            setQuery({ ...Query, [name]: e })
        }
        setCurrent(1)
    }


    useEffect(() => {
        GetData()
    }, [Query, current, size])
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">RC Reminder</h3>
                <div className="page-heading-right">
                    <div className="inputgroup my-1">
                        <Form.Control
                            type="text"
                            name="searchvalue"
                            placeholder="Search Registration No."
                            className="wv-250 ms-3"
                            onChange={(e) => QueryHendler(e, "searchvalue")}
                        />
                        <i className="bx bx-search"/>
                    </div>
                    <SelectPicker
                        cleanable={false}
                        data={Remider_type_Data}
                        searchable={false}
                        defaultValue={""}
                        className="wv-200 my-1 ms-3"
                        placement="bottomEnd"
                        placeholder="Select Reminder Type"
                        onChange={(e) => QueryHendler(e, "reminder_type")}
                    />
                    <SelectPicker
                        cleanable={false}
                        data={Doc_type_Data}
                        searchable={false}
                        defaultValue={""}
                        className="wv-200 my-1 ms-3"
                        placement="bottomEnd"
                        placeholder="Select Doc Type"
                        onChange={(e) => QueryHendler(e, "doc_type")}
                    />
                    <SelectPicker
                        cleanable={false}
                        data={State_Data}
                        searchable={false}
                        defaultValue={""}
                        className="wv-150 my-1 ms-3"
                        placement="bottomEnd"
                        placeholder="Select State"
                        onChange={(e) => QueryHendler(e, "state")}
                    />
                    <SelectPicker
                        cleanable={false}
                        data={PageHook}
                        searchable={false}
                        value={size}
                        className="wv-100 my-1 ms-3"
                        placement="bottomEnd"
                        onChange={(e) => {
                            setSize(Number(e));
                            setCurrent(1);
                        }}
                    />
                </div>
            </div>
            <div className="page-content">
                <Card className="radiustop-0">
                    <Card.Body>
                        {loading && <div className="loader table-loader"></div>}
                        <Table className="table-bg-primary" striped bordered responsive>
                            <thead>
                                <tr>
                                    <th width="5%" className="text-center">No</th>
                                    <th width="10%" className="text-center">User ID</th>
                                    <th width="15%">User</th>
                                    <th width="15%">Registration No.</th>
                                    <th width="15%">Document Type</th>
                                    <th width="40%">Reminder Type</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Data?.data?.length > 0 ? Data?.data?.map((val, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className='text-center'>{(current === 1) ? i + 1 : current * size + i + 1 - size}</td>
                                                <td className="text-center">{val.user_id}</td>
                                                <td>{val.name}</td>
                                                <td>{val.reg_number}</td>
                                                <td>
                                                    {
                                                        val.doc_type == 1 ? "Driving lincese" :
                                                            val.doc_type == 2 ? "Insurance" :
                                                                val.doc_type == 3 ? "PUC" :
                                                                    val.doc_type == 4 ? "Registration certificate" :
                                                                        val.doc_type == 5 ? "Service Log" :
                                                                            val.doc_type == 6 ? "Other Document" :
                                                                                val.doc_type == 7 ? "Fitness Upto" : ""
                                                    }
                                                </td>
                                                <td>
                                                    {
                                                        val.reminder_type.split(",").map((v) => {
                                                            return (
                                                                v == "1" ?
                                                                    <Button variant="primary" size="sm" className="me-2 p-2 py-0">Before a month</Button>
                                                                    : v == "2" ?
                                                                        <Button variant="primary" size="sm" className="me-2 p-2 py-0">Before a Week</Button>
                                                                        : v == "3" ?
                                                                            <Button variant="primary" size="sm" className="me-2 p-2 py-0">Before 1 Day</Button>
                                                                            : v == "4" ?
                                                                                <Button variant="primary" size="sm" className="me-2 p-2 py-0">Same Day</Button>
                                                                                : ""
                                                            )

                                                        })
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }) : <tr>
                                        <td colSpan="100%" className="p-0">
                                            <div className='no-found'>
                                                <img src="../../not-found/image.svg" />
                                                <p>No Found RC Reminder</p>
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </Table>
                        {Data?.totalcount > size ? (
                            <div className="pagination-custom">
                                <Pagination
                                    showTitle={false}
                                    className="pagination-data"
                                    onChange={paginationData}
                                    total={Data?.totalcount}
                                    current={current}
                                    pageSize={size}
                                    showSizeChanger={false}
                                />
                            </div>
                        ) : (
                            ""
                        )}
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default RcReminder