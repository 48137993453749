import dayjs from "dayjs";
import Cookies from "js-cookie";
import Pagination from "rc-pagination";
import React, { useEffect, useState } from "react";
import { Button, Card, Form, InputGroup, Modal, Table } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import { API, APIS } from "../../App";
import Layout from '../../layout/Layout';
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";

const data = ['Eugenia', 'Bryan', 'Linda', 'Nancy', 'Lloyd', 'Alice', 'Julia', 'Albert'].map(
    item => ({ label: item, value: item })
);
  
const RcDashboard = () => {
    const [size, setSize] = useState(10);
    const [current, setCurrent] = useState(1);
    const [loading, setIsLoading] = useState(false)

    const paginationData = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize);
    };

    const [dateRanges, setdateRanges] = useState({
        start: moment().subtract(29, 'days'),
        end: moment(),
    });
    const { start, end } = dateRanges;

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">RC Dashboard <span>123</span></h3>
                <div className="page-heading-right">
                    <div className="inputgroup my-1">
                        <Form.Control
                            type="text"
                            name="searchvalue"
                            placeholder="Search Registation No."
                            className="wv-225 ms-3"
                        />
                        <i className="bx bx-search"/>
                    </div>

                    <SelectPicker
                        cleanable={false}
                        data={data}
                        searchable={false}
                        defaultValue={""}
                        placeholder="Select Role"
                        placement="bottomEnd"
                        className="wv-200 my-1 ms-3"
                    />

                    <SelectPicker
                        cleanable={false}
                        data={data}
                        searchable={false}
                        defaultValue={""}
                        className="wv-150 my-1 ms-3"
                        placeholder="Select Date"
                    />

                    <DateRangePicker initialSettings={{ 
                        startDate: start.toDate(),
                        endDate: end.toDate(),
                        ranges: {
                            Today: [moment().toDate(), moment().toDate()],
                            Yesterday: [
                                moment().subtract(1, 'days').toDate(),
                                moment().subtract(1, 'days').toDate(),
                            ],
                            'Last 7 Days': [
                                moment().subtract(6, 'days').toDate(),
                                moment().toDate(),
                            ],
                            'Last 30 Days': [
                                moment().subtract(29, 'days').toDate(),
                                moment().toDate(),
                            ],
                            'This Month': [
                                moment().startOf('month').toDate(),
                                moment().endOf('month').toDate(),
                            ],
                            'Last Month': [
                                moment().subtract(1, 'month').startOf('month').toDate(),
                                moment().subtract(1, 'month').endOf('month').toDate(),
                            ],
                        },
                        }}>
                        <input type="text" name="date" placeholder="Select Date" className="form-control wv-225 my-1 ms-3 " />
                    </DateRangePicker>

                    <SelectPicker
                        cleanable={false}
                        data={data}
                        searchable={false}
                        defaultValue={10}
                        className="wv-100 my-1 ms-3"
                    />

                </div>
            </div>
            <div className="page-content">
                <Card className="radiustop-0">
                    <Card.Body>
                        {/* {loading && <div className="loader table-loader"></div>} */}
                        <Table className="table-bg-primary" bordered responsive striped>
                            <thead>
                                <tr>
                                    <th width="5%" className="text-center">No</th>
                                    <th width="20%">Email Address</th>
                                    <th width="10%">Mobile No.</th>
                                    <th width="15%">Role</th>
                                    <th width="50%">Registration No</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='text-center'>1</td>
                                    <td>KarenTWilson@rhyta.com</td>
                                    <td>+91 987654321</td>
                                    <td>Vehicle Owner</td>
                                    <td>AS14H8966</td>
                                </tr>
                                {/* <tr>
                                    <td colSpan="100%" className="p-0">
                                        <div className='no-found'>
                                            <img src="../../not-found/image.svg" />
                                            <p>No Found RC Dashboard</p>
                                        </div>
                                    </td>
                                </tr> */}
                            </tbody>
                        </Table>
                        <div className="pagination-custom">
                            <Pagination
                                showTitle={false}
                                className="pagination-data"
                                onChange={paginationData}
                                total={100}
                                current={current}
                                pageSize={size}
                                showSizeChanger={false}
                            />
                        </div>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default RcDashboard