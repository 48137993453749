import React, { useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, Row } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import Layout from '../../layout/Layout';
import { Link, useNavigate, useParams } from "react-router-dom";
import { deletePartner, getPartnerData } from "./service";
import Swal from "sweetalert2";
import $ from "jquery";

const dataType = ['Eugenia', 'Bryan', 'Linda', 'Nancy', 'Lloyd', 'Alice', 'Julia', 'Albert'].map(
    item => ({ label: item, value: item })
);

const RcPartnerView = () => {
    const { id } = useParams();
    const navigate = useNavigate()
    const [loading, setIsLoading] = useState(false)
    const [partner, setPartner] = useState([])
    const fetchPartner = async () => {
        setIsLoading(true)
        try {
            const formData = new FormData()
            formData?.append("id", id)
            const response = await getPartnerData(formData)
            if (response?.status) {
                setPartner(response?.data)
            }
        } catch (error) {
            console.log('error', error)
        } finally {
            setIsLoading(false)
        }
    }

    /**delete service*/
    let count = 10
    let swalCountdownInterval
    const handleDelete = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: '../../icon/alert.svg',
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr('disabled', true);
                    swalCountdownInterval = setInterval(function () {
                        count--
                        if (count < 1) {
                            $(".counter").text(`OK`)
                            $(".swal2-confirm").attr('disabled', false);
                            clearInterval(swalCountdownInterval)
                        } else {
                            $(".counter").text(`OK (${count})`)
                        }
                    }, 1000);
                }
            })
            .then(async (result) => {
                try {

                    if (result.isConfirmed) {
                        const formData = new FormData()
                        formData?.append("id", id)
                        const deletedata = await deletePartner(formData)
                        navigate("/rc-partner")
                    } else {
                        count = 10
                        clearInterval(swalCountdownInterval)
                    }
                } catch (error) {
                    console.log('error', error)
                }
            });
    };

    useEffect(() => {
        fetchPartner()
    }, [])
    return (
        <Layout sidebar={true}>
            <div className="page-heading backbtn">
                <h3>
                    <Link to="/rc-partner" className='btn btn-transparent btn-icon me-2'>
                        <i className='bx bx-chevron-left' ></i>
                    </Link>
                    RC Partner Report View
                </h3>
                <div className="page-heading-right">
                    {/* <SelectPicker
                        data={dataType}
                        cleanable={false}
                        className="wv-200 my-1 ms-3"
                        placeholder="Select Categories"
                        placement="bottomEnd"
                    /> */}

                    <Link to={`/rc-partner/edit/${id}`} className="btn btn-primary ms-3 my-1">Edit</Link>

                    <Button variant="danger ms-3 my-1 btn-icon-lg" onClick={()=>handleDelete(id)} type="button"><i className="bx bx-trash-alt"></i></Button>
                </div>
            </div>
            <div className='page-content'>

                <Card className="mb-4 radiustop-0">
                    <Card.Body>
                        <Row>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Partner Name</p><span>{partner?.name}</span>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Status</p><span>{partner?.status == 1 ? "On" : "Off"}</span>
                                </div>
                            </Col>
                        </Row>
                        {partner?.services?.map((item,index)=>{return(<Row className="my-3" key={index}>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Service Name</p><span>{item?.service_name?item?.service_name:"-"}</span>
                                </div>
                            </Col>
                            <Col md={4}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Limit Type</p><span>{item?.limit_type?item?.limit_type:"-"}</span>
                                </div>
                            </Col>
                            <Col md={2}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Count</p><span>{item?.limit_count?item?.limit_count:"-"}</span>
                                </div>
                            </Col>
                            <Col md={2}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Status</p><span>{item?.status == 1 ? "On" : "Off"}</span>
                                </div>
                            </Col>
                        </Row>)})}
                    </Card.Body>

                </Card>
            </div>
        </Layout>
    )
}

export default RcPartnerView