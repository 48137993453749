import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Layout from '../layout/Layout';
import ApexCharts from 'react-apexcharts'
import { SelectPicker } from 'rsuite';
import Request from '../Component/Chart/Request';
import Source from '../Component/Chart/Source';
import State from '../Component/Chart/State';
import VehicleFuelType from '../Component/Chart/VehicleFuelType';
import VehicleType from '../Component/Chart/VehicleType';
import VehicleAgeType from '../Component/Chart/VehicleAgeType';

const data = ['Eugenia', 'Bryan', 'Linda', 'Nancy', 'Lloyd', 'Alice', 'Julia', 'Albert'].map(
    item => ({ label: item, value: item })
);

const Home = () => {
    const bluecolor = "#008FFB"
    const yellowcolor = "#FEB019"
    const greencolor = "#00D8B6"
    const redcolor = "#FF4560"
    const purplecolor = "#775DD0"

    const chartoption = {
        chart: {
            type: 'pie',
        },
        legend: {
            show: false,
        },
        states: {
            hover: {
                filter: {
                    type: 'none',
                    value: 0,
                }
            },
            active: {
                filter: {
                    type: 'none',
                    value: 0,
                }
            },
        },
        tooltip: {
            enabled: false,
        },
        dataLabels: {
            textAnchor: 'middle',
            style: {
                fontFamily: 'Maven Pro',
                fontSize: '10px',
                fontWeight: '500',
            },
            dropShadow: {
                enabled: false,
            },
            formatter: function (val) {
                return val + "%"
            },
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    decimalsInFloat: 0,
                    offset: -10,
                }
            }
        }
    }

    const [chartTotalRC, setChartTotalRC] = useState({
        series: [75, 25],
        options: {
            colors: [bluecolor, yellowcolor],
            ...chartoption
        }
    });

    const [chartTotalChallan, setChartTotalChallan] = useState({
        series: [75, 25],
        options: {
            colors: [greencolor, redcolor],
            ...chartoption
        }
    });

    const [chartTotalRequest, setChartTotalRequest] = useState({
        series: [75, 25],
        options: {
            colors: [yellowcolor, purplecolor],
            ...chartoption
        }
    });

    return (
        <Layout sidebar={true}>
            <div className="vi-dashboard">
                <Row className='g-4'>
                    <Col xs={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <div className="chart-title">
                                    <h4>Data Summary</h4>
                                    <div className='chartfilter'>
                                        <SelectPicker
                                            cleanable={false}
                                            data={data}
                                            size="md"
                                            searchable={false}
                                            defaultValue={""}
                                            placeholder="Yesterday"
                                            placement="bottomEnd"
                                            className="wv-175 ms-3"
                                        />
                                    </div>
                                </div>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col xl={4} md={6}>
                                        <div className="countdata blue">
                                            <div className="countdata-content">
                                                <p>Total RC</p>
                                                <h3>1,15,237,131</h3>
                                                <ul>
                                                    <li><p><span className='txtgreen'><i className='bx bx-trending-up'></i> 86,427,848</span> Full RC</p></li>
                                                    <li><p><span className='txtred'><i className='bx bx-trending-down' ></i> 28,809,278</span> Partial RC</p></li>
                                                </ul>
                                            </div>
                                            <div className="countdata-chart">
                                                <ApexCharts options={chartTotalRC.options} series={chartTotalRC.series} height={125} width={125} type="pie" />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl={4} md={6}>
                                        <div className="countdata red">
                                            <div className="countdata-content">
                                                 <p>Total Challan</p>
                                                <h3>65,384,361</h3>
                                                <ul>
                                                    <li><p><span className='txtgreen'><i className='bx bx-trending-up'></i>42,959,834</span> Success</p></li>
                                                    <li><p><span className='txtred'><i className='bx bx-trending-down' ></i>22,424,527</span> Fail</p></li>
                                                </ul>
                                            </div>
                                            <div className="countdata-chart">
                                                <ApexCharts options={chartTotalChallan.options} series={chartTotalChallan.series} height={125} width={125} type="pie" />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl={4} md={6}>
                                        <div className="countdata purple">
                                            <div className="countdata-content">
                                                <p>Total Request</p>
                                                <h3>1,80,621,492</h3>
                                                <ul>
                                                    <li><p><span className='txtgreen'><i className='bx bx-trending-up'></i>1,08,372,895</span> Success</p></li>
                                                    <li><p><span className='txtred'><i className='bx bx-trending-down' ></i>72,248,597</span> Fail</p></li>
                                                </ul>
                                            </div>
                                            <div className="countdata-chart">
                                                <ApexCharts options={chartTotalRequest.options} series={chartTotalRequest.series} height={125} width={125} type="pie" />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col sm={12}>
                        <Request />
                    </Col>
                    <Col sm={12}>
                        <State />
                    </Col>
                    <Col sm={12}>
                        <Source />
                    </Col>
                    <Col sm={4}>
                        <VehicleFuelType />
                    </Col>
                    <Col sm={4}>
                        <VehicleType />
                    </Col>
                    <Col sm={4}>
                        <VehicleAgeType />
                    </Col>
                </Row>
            </div>
        </Layout>
    )
}

export default Home